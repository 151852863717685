import React, { useEffect, useState } from "react";
import { Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const IMAGES = {
  flags1: require('../assets/images/flags/us.png'),
  flags3: require('../assets/images/flags/fr.png'),
  flags4: require('../assets/images/flags/hk.png'),
  flags5: require('../assets/images/flags/ru.png'),
  flags6: require('../assets/images/flags/so.png'),
  
  pakistan: require('../assets/images/flags/pk.png'),
  india: require('../assets/images/flags/in.png'),
  korean: require('../assets/images/flags/kr.png'),
  thailand: require('../assets/images/flags/th.png'),
  myanmar: require('../assets/images/flags/mm.png'),
  vietnam: require('../assets/images/flags/vn.png'),
  es: require('../assets/images/flags/es.png'),
};

const selectData = [
  {
    image: IMAGES.flags1,
    title: "en",
    displayName: "English",
  },
  {
    image: IMAGES.myanmar,
    title: "my",
    displayName: "Burmese",
  },
  {
    image: IMAGES.flags3,
    title: "fr",
    displayName: "French",
  },
  {
    image: IMAGES.flags4,
    title: "hmk",
    displayName: "Hmoob",
  },
  {
    image: IMAGES.india,
    title: "hi",
    displayName: "Hindi",
  },
  {
    image: IMAGES.korean,
    title: "ko",
    displayName: "Korean",
  },
  {
    image: IMAGES.flags6,
    title: "so",
    displayName: "Soomaali",
  },
  {
    image: IMAGES.es,
    title: "es",
    displayName: "Spanish",
  },
  {
    image: IMAGES.flags5,
    title: "ru",
    displayName: "Russian",
  },
 
  {
    image: IMAGES.thailand,
    title: "lo",
    displayName: "Thai",
  },
  {
    image: IMAGES.pakistan,
    title: "ur",
    displayName: "Urdu",
  },
  {
    image: IMAGES.vietnam,
    title: "vi",
    displayName: "Vietnamese",
  },

];

const LanguagePopup = ({ visible, onClose }) => {
  let { t, i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    const getStoredLanguage = () => {
      const storedLanguage = localStorage.getItem("language");
      const currentLanguage = storedLanguage || i18n.language || "en";
      const currentLanguageItem = selectData.find(
        (lang) => lang.title === currentLanguage
      );
      if (currentLanguageItem) {
        setSelectedLanguage(currentLanguageItem);
        i18n.changeLanguage(currentLanguageItem.title).catch((error) => {
          console.error("Language change error:", error);
        });
      }
    };

    getStoredLanguage();
  }, [i18n]);

  const handleChangeLanguage = async (value) => {
    const language = selectData.find((lang) => lang.title === value);
    if (language) {
      setSelectedLanguage(language);
      localStorage.setItem("language", language.title);
      i18n.changeLanguage(language.title).catch((error) => {
        console.error("Language change error:", error);
      });
      onClose();
    }
  };

  return (
    <Modal
      title={t('Choose a Language')}
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <Select
        defaultValue={selectedLanguage?.title}
        style={{ width: '100%' }}
        onChange={handleChangeLanguage}
      >
        {selectData.map((lang) => (
          <Option key={lang.title} value={lang.title}>
            <img
              src={lang.image}
              alt={lang.displayName}
              style={{ width: 24, marginRight: 8 }}
            />
            {lang.displayName}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};

export default LanguagePopup;
