import React,{useState,useEffect} from 'react';
import { Layout,Menu, Row, Col ,Segmented } from "antd";
import { NavLink, useLocation,useHistory } from "react-router-dom";
import { HomeOutlined, HistoryOutlined, ScheduleOutlined,UsergroupAddOutlined,CarryOutOutlined,ReloadOutlined , EnterOutlined,DollarOutlined,PhoneOutlined,UserSwitchOutlined  ,ReadOutlined,MedicineBoxOutlined ,AuditOutlined  } from '@ant-design/icons';
import MyLocation from "./../../utility/MyLocation";
import { useTranslation } from 'react-i18next';
const customOrder = ['Home', 'Manual Entry', 'Multi Schedular', 'History', 'Schedular'];
const tabs = ['home', 'manualEntry', 'multiScheduler', 'history', 'schedular'];

const Footer = ({ color,menuType }) => {
  const {t} = useTranslation();
  const menuNameItem = menuType;
  const history = useHistory();
  const { Footer: AntFooter } = Layout;
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [menuItems, setMenuItems] = useState(JSON.parse(localStorage.getItem('Menus')));
  const [mappedMenuItems, setMappedMenuItems] = useState([]);
  const [menuName, setMenuName] = useState(menuType);
  const [key, setKey] = useState(0);
  useEffect(() => {
    updateKey();
  },[menuName])
useEffect(() => {
  const handleStorageChange = () => {
    updateKey();
    const newMenuItems = JSON.parse(localStorage.getItem('Menus'));
    let filteredMenus = newMenuItems?.filter(menu =>
      menu.name !== "Open Schedular" &&
      menu.name !== "Earning" &&
      menu.name !== "Offline Mode" &&
      menu.name !== "Contact" &&
      menu.name !== "Profile" &&
      menu.name !== "Clients" &&
      menu.name !== "Availability" &&
      menu.name !== "Training & Courses" &&
      menu.name !== "Vacation" &&
      menu.name !== "Emar"
    );
    filteredMenus?.sort((a, b) => {
      return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
    });
    setMenuItems(filteredMenus);
  };


  window.addEventListener('storage', handleStorageChange);

  return () => {
    // Remove event listener on cleanup
    window.removeEventListener('storage', handleStorageChange);
  };
}, []);

const handleSegmentedClick = (url) => {
  history.push(url); // Navigate to the provided URL
};
 useEffect(() => {
  // updateKey();
  let filteredMenus = menuItems?.filter(menu =>
    menu.name !== "Open Schedular" &&
      menu.name !== "Earning" &&
      menu.name !== "Contact" &&
      menu.name !== "Offline Mode" &&
      menu.name !== "Profile" &&
      menu.name !== "Clients" &&
      menu.name !== "Availability" &&
      menu.name !== "Training & Courses" &&
      menu.name !== "Vacation" &&
      menu.name !== "Emar"
  );
  filteredMenus?.sort((a, b) => {
    return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
  });
  // setMenuItems(filteredMenus);
    // Map menuItems and store in separate state variable
    setMappedMenuItems(filteredMenus?.map((item, index) => ({
      value: item.url,
      icon: (
        <div>
        {getIconComponent(item.icon)}
      </div>
      ),
      // icon: getIconComponent(item.icon),
      label: t(item.label),
    })));
  }, [menuItems]);
  const updateKey = () => {
    setKey(prevKey => prevKey + 1);
  };
  return (
    
    <AntFooter style={{ background: "#fafafa",marginTop:"20px !important"}} className="fixed-footer">
         {/* <div>
        <Menu  theme="dark" mode="horizontal"  onClick={handleMenuClick}>
        {mappedMenuItems}
        </Menu>
        </div> */}
        {/* defaultSelectedKeys={['/home']} */}


          {/* <Menu.Item key="home" icon={<HomeOutlined />}>Home</Menu.Item>
          <Menu.Item key="about" icon={<HomeOutlined />}>About</Menu.Item>
          <Menu.Item key="services" icon={<HomeOutlined />}>Services</Menu.Item>
          <Menu.Item key="contact" icon={<HomeOutlined />}>Contact</Menu.Item>
          <Menu.Item key="portfolio" icon={<HomeOutlined />}>Portfolio</Menu.Item>
          <Menu.Item key="team" icon={<HomeOutlined />}>Team</Menu.Item>
          <Menu.Item key="blog" icon={<HomeOutlined />}>Blog</Menu.Item>
          <Menu.Item key="pricing" icon={<HomeOutlined />}>Pricing</Menu.Item>
          <Menu.Item key="faq" icon={<HomeOutlined />}>FAQ</Menu.Item> */}
          {/* Add more Menu.Items for other menu items */}
       
   
      {/* <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            <MyLocation />
          </div>
        </Col>
      </Row> */}
       <Row className="just">
        <Col xs={24} md={24} lg={20}>
          {/* <div className="copyright"> */}
          {/* <Menu  theme="dark" mode="horizontal"  onClick={handleMenuClick} defaultSelectedKeys={['/home']}> */}
        {/* {mappedMenuItems} */}
      {/* You can also use a button instead of a div */}
      {/* {tabs.includes(menuNameItem) ? <h2>ffff{menuName}</h2> : <h2>ttttt{menuName}</h2> } */}
     
     {tabs.includes(menuNameItem) &&
      <Segmented key={key} size='small' defaultValue={'/'+t(menuNameItem)} className='Bottom-menu-segm' onChange={(url) => {handleSegmentedClick(url)}} options={mappedMenuItems} block />}
     
     {!tabs.includes(menuNameItem) &&
      <Segmented size='small' defaultValue={null} className='Bottom-menu-segm' onChange={(url) => {handleSegmentedClick(url)}} options={mappedMenuItems} block />}
    
    
        {/* </Menu> */}
            {/* <MyLocation />  */}
          {/* </div> */}
        </Col>
      </Row>
    </AntFooter>
  );
}
const getIconComponent = (iconName) => {
  switch(iconName) {
    case '<HomeOutlined />':
      return <HomeOutlined />;
    case '<HistoryOutlined />':
      return <HistoryOutlined />;
    case '<EnterOutlined />':
      return <EnterOutlined />;
    case '<MedicineBoxOutlined />':
      return <MedicineBoxOutlined />;
    case '<ScheduleOutlined />':
      return <ScheduleOutlined />;
    case '<DollarOutlined />':
      return <DollarOutlined />;
    case '<ReadOutlined />':
      return <ReadOutlined />;
    case '<AuditOutlined />':
      return <AuditOutlined />;
    case '<UsergroupAddOutlined />':
      return <UsergroupAddOutlined />;
    case '<PhoneOutlined />':
      return <PhoneOutlined />;
    case '<UserSwitchOutlined />':
      return <UserSwitchOutlined />;
    case '<CarryOutOutlined />':
      return <CarryOutOutlined />;
    case '<ReloadOutlined />':
      return <ReloadOutlined />;
    default:
      return null;
  }
}
export default Footer;
