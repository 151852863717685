import React, { useState, useEffect,useRef } from "react";
import AxiosInstance from "./../services/axiosInstance";
import { useHistory } from "react-router-dom";
import fetchGeolocation from "../utility/CurrentLocation";
import SignatureCanvas from 'react-signature-canvas';
import { Message } from "../utility/MessageUtil";
import { RefreshComponet } from "../utility/UserDetails";
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  TimePicker,
  Row,
  Col,
  Modal,
  Card,
  Spin,
  Checkbox,
  Divider,
  Typography,
  Badge
} from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Toaster } from "../utility/Toaster";
import moment from "moment";
import dayjs from "dayjs";
import SignatureModal from "./home/Cli-Emp-Signatures";
import { useTranslation } from "react-i18next";



const { Option } = Select;
const { confirm } = Modal;
const { Meta } = Card;



const MultiScheduler = () => {
  const state = localStorage.getItem("state") || "";
  const { t } = useTranslation();
  const isAllowedPayrollGracePeriod =  localStorage.getItem("isAllowedPayrollGracePeriod")
  const payrolGracePeriod =  localStorage.getItem("payrolGracePeriod")
  const payrloGPeriod = isAllowedPayrollGracePeriod && payrolGracePeriod ? payrolGracePeriod : 0;
  const Formfield = [
    {
      name: "sDate",
      label: t("StartDate"),
      type: "date",
      "min_date": state === 'IL' ? moment(new Date()).subtract(1, "day") : {},
      "max_date": {},
      value: "",
      placeholder: t("Select Start Date"),
      required: true,
    },
    {
      name: "eDate",
      label: t("EndDate"),
      type: "date",
      "min_date": state === 'IL' ? moment(new Date()) : {},
      "max_date": {},
      value: "",
      placeholder: t("Select End Date"),
      required: true,
    },
    {
      name: "clientId",
      label: "Client",
      type: "select_client",
      placeholder:t( "SelectClient"),
      required: true,
    },
    {
      name: "auth",
      label: t("Service"),
      type: "select_service",
      placeholder: t("Select Service"),
      required: true,
    },
    {
      name: "sTime",
      label: t("Start Time"),
      type: "time",
      placeholder: t("Select Start Time"),
      value: "",
      required: true,
    },
    {
      name: "eTime",
      label: t("End Time"),
      type: "time",
      placeholder: t("Select End Time"),
      value: "",
      required: true,
    },
  ];
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const { Title } = Typography;
  const clientSigCanvas = useRef();
  const redirectSchedule = useHistory();
  const [fields, setFields] = useState(Formfield);
  const [clientList, setclientList] = useState([]);
  const [clientServices, setClientServices] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});
  const [getPinValue, setPinValue] = useState('');
  const [formValues, setFormValues] = useState({});
  const [serviceDetails, setServiceDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [warningLoading, setWarningLoading] = useState(false);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [scheduleList, setScheduleList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAllActive, setSelectAllActive] = useState(true);
  const [showEndSchBtn, setshowEndSchBtn] = useState(false);
  const [showEmployeeSignature, setShowEmployeeSignature] = useState(false);
  const [showClientSignature, setShowClientSignature] = useState(false);
  const [getDataForClientPin, setDataForClientPin] = useState(null);
  const [showClientSignatureBox, setShowClientSignatureBox] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [actualPosition, setactualPosition] = useState({ latitude: null, longitude: null });
  const [isChecked, setIsChecked] = useState(false);
  const [isCommunictyChecked, setIsCommunictyChecked] = useState(false);
  const latestIsChecked = useRef(isChecked);
  const latestIsCommunictyChecked = useRef(isCommunictyChecked);
  const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
  const ServiceTitle = userInfo?.title?.name

  useEffect(() => {
    latestIsChecked.current = isChecked;
  }, [isChecked]);

  useEffect(() => {
    latestIsCommunictyChecked.current = isCommunictyChecked;
  }, [isCommunictyChecked]);
  const [schData, setSchData] = useState({
    services: [],
    tasks: [],
  });
  const [signnature, setSignnature] = useState({
    clientId: '',
    sDate: dayjs(),
    eDate: dayjs(),
    eSign: "",
    lat: "",
    lng: "",

  });
  
  useEffect(() => {
    form.resetFields();

    if(state !== 'IL') getEmpCutSchHr(payrloGPeriod);
    const url = `emp/my-client`;
    AxiosInstance.get(url)
      .then((res) => {
        if(res.data.dirtyBit) RefreshComponet(res.data.dirtyBit)
        const clients = res.data.data;
        setclientList(clients);
        if (clients.length === 1) {
          form.setFieldsValue({ clientId: clients[0]._id });
          // setFormValues({clientId:clients[0]._id,sDate: dayjs(today, 'DD/MM/YYYY').format('YYYY/MM/DD')});
          handleClientChange(clients[0]._id);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    const today = moment(new Date()).format("DD/MM/YYYY");
    const time = moment(new Date()).format("hh:mm A");
    form.setFieldsValue({
      sDate: dayjs(today, "DD/MM/YYYY"),
      eDate: dayjs(today, "DD/MM/YYYY"),
      sTime: dayjs(time, "hh:mm A"),
      eTime: dayjs(time, "hh:mm A"),
    });
  }, []);

  useEffect(() => {
    if (formValues.sDate && formValues.clientId) handleClientChange();
  }, [formValues.sDate, formValues.clientId]);

  useEffect(() => {
    // if (clientServices.length === 1) {
     if(form.getFieldValue('auth')) handleServiceChange(form.getFieldValue('auth'));
      // form.setFieldsValue({ auth: clientServices[0]._id });
    // }
  }, [formValues.auth]);


  
  // useEffect(() => {
  //   if (clientServices.length == 1) {
  //     handleServiceChange(clientServices[0]._id);
  //     form.setFieldsValue({ auth: clientServices[0]._id });
  //   }
  // }, [clientServices]);

  useEffect(() => {
    fetchGeoloc();
  }, []);
  const getEmpCutSchHr = (payrloGPeriod) => {
    const today = moment(new Date()).format("DD/MM/YYYY");
    // setWarningLoading(true);
let endPoint = "emp/cut-sch-hours";
// AxiosInstance.get(endPoint, { params: { date: dayjs().subtract(3, 'day').format('MM-DD-YYYY') } })
AxiosInstance.get(endPoint, {
  params: { date: moment(new Date()).subtract(payrloGPeriod || 0, 'day').format('MM/DD/YYYY')} 
})
  .then((res) => {
    if (res.data.success) {
      const data = res.data.data;
      if (data.eDate && data.sDate) {
        const sd = moment(new Date(data.sDate)).format("DD/MM/YYYY");
        const ed = moment(new Date(data.eDate)).format("DD/MM/YYYY");
        form.setFieldsValue({
          sDate: dayjs(sd, "DD/MM/YYYY").add(1, 'day'),
          eDate: dayjs(ed, "DD/MM/YYYY"),
        });
        // schData.sDate = dayjs(res.data.sDate).format("YYYY-MM-DD");
        // schData.eDate = dayjs(res.data.eDate).format("YYYY-MM-DD");
      } else {
        form.setFieldsValue({
          sDate: dayjs(today, "DD/MM/YYYY"),
          eDate: dayjs(today, "DD/MM/YYYY"),
        });
      }
    } else {
      form.setFieldsValue({
        sDate: dayjs(today, "DD/MM/YYYY"),
        eDate: dayjs(today, "DD/MM/YYYY"),
      });
    }
  })
  .catch((error) => {
  });
};
  const fetchGeoloc = async () => {
    try {
      const location = JSON.parse(localStorage.getItem("location"));

      if (location) {
        setPosition({
          latitude: parseFloat(location.lat),
          longitude: parseFloat(location.long),
        });
        setactualPosition({
          latitude: parseFloat(location.lat),
          longitude: parseFloat(location.long),
        });
      } else {
        const fetchedPosition = await fetchGeolocation();
        setPosition({
          latitude: fetchedPosition.latitude,
          longitude: fetchedPosition.longitude,
        });
        setactualPosition({
          latitude: fetchedPosition.latitude,
          longitude: fetchedPosition.longitude,
        });
      }
    } catch (error) {
        Toaster(
            "error",
            "Error fetching geolocation:",
            error.message
          );
    //   console.error("Error fetching geolocation:", error.message);
    }
  };
  const validatePin = (values) => {
    let endPoint = "client/pin-remote";
    AxiosInstance.post(endPoint, { clientId: values })
      .then(({ data }) => {
        if (data.success) {
          Message.success(data.message);
        } else {
          Toaster("error", "Error", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleFormChange = (changedValues, allValues) => {
    if (changedValues.sDate && changedValues.sDate.$d) changedValues.sDate = dayjs(allValues.sDate.$d).format("YYYY-MM-DD");
    setFormValues(prevState => ({...prevState,...changedValues}));
    // setFormValues(allValues);
    // if (changedValues.sDate)
  };
  const handleClientChange = () => {
    const sDate = dayjs(form.getFieldValue("sDate").$d).format("YYYY-MM-DD");
    const clientId = form.getFieldValue('clientId') || formValues.clientId ;
    if ((formValues.clientId && formValues.sDate) || (sDate && clientId)) {
      handleReset("auth");
      setClientServices([]);
      setServiceDetails({});
      // Fetch client services based on selected client from API
      const url = `client/service-agreement-dates?client_id=${clientId || formValues.clientId}&date=${formValues.sDate || sDate}`;
      AxiosInstance.get(url)
        .then((res) => {
          if (res.data.success) {
            const services = res?.data?.data;
            setClientServices(services);
            if (services?.length === 1) {
              setFormValues(prevState => ({...prevState,auth:services[0]._id}));
              form.setFieldsValue({ auth: services[0]._id });
              handleServiceChange(services[0]._id);
            }
            else {
              if(ServiceTitle){
              const filteredArray = res?.data?.data.filter((data) => data?.service?.desc.includes(ServiceTitle));
              if(filteredArray?.length){
              setFormValues(prevState => ({...prevState,auth:filteredArray[0]._id}));
              form.setFieldsValue({ auth: filteredArray[0]._id });
              handleServiceChange(filteredArray[0]._id);
              }
              }
            }
            // else {
            //   if(ServiceTitle){
            //   const filteredArray = res?.data?.data.filter((data) => data.service.desc.includes(ServiceTitle));
            //   setFormValues(prevState => ({...prevState,auth:filteredArray[0]._id}));
            //   form.setFieldsValue({ auth: filteredArray[0]._id });
            //   handleServiceChange(filteredArray[0]._id);
             
            //   }
            // }
          }else{
            setClientServices([]);
            form.setFieldsValue({ auth: "" });
          }
          // if (res.data.data.length == 1) {
          //   setFormValues(prevState => ({...prevState,auth:res.data.data[0]._id}));
          //   form.setFieldsValue({ auth: res.data.data[0]._id });
          //   handleServiceChange(res.data.data[0]._id);
          // }
          // if (res.data.success) setClientServices(res.data.data);
          // else setClientServices([]);
        })
        .catch((error) => {
          console.error("Error fetching services:", error);
        });
    }
  };
  const handleServiceChange = (value) => {
    const val = clientServices.find((service) => service._id === value);
    if (val) {
      setServiceDetails(val);
      const sDate = new Date(val.sDate);
      sDate.setUTCHours(12);
      const eDate = new Date(val.eDate);
      eDate.setUTCHours(12);
      if (
        sDate > new Date(formValues.sDate) &&
        eDate < new Date(formValues.sDate)
      ) {
        handleReset("auth");
        setServiceDetails({});
      }
    } else {
      // setServiceDetails({});
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  const handleSelectAll = () => {
    const allIds = scheduleList.map((item) => item._id);
    setSelectedIds(allIds); // Update selectedIds with all IDs from data
    setSelectAllActive(!selectAllActive); // Hide "Select All" button
  };
  const handleDeselectAll = () => {
    setSelectedIds([]);
    setSelectAllActive(!selectAllActive); // Hide "Deselect All" button
  };
  const format = "h:mm A";
  const editSchedule = () =>{
    if(selectedIds.length){
    const sTime = scheduleList.map(s => s.sTime)
    const eTime = scheduleList.map(e => e.eTime)
    let newSTime = dayjs(sTime[0], format);
    let newETime = dayjs(eTime[0], format);
    Modal.confirm({
      title: t("Do you want to edit  start and end of this schedule?"),
      okText: t("Yes"),
      confirmLoading: loading,
      icon: <ExclamationCircleFilled />,
      content: (
        <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
          <label>{t("StartTime")}</label>
          <TimePicker
            defaultValue={newSTime}
            onChange={(time) => (newSTime = time)}
            // onChange={(e) => handleChange(e)}
            format={format}
            placeholder="Select Start Time"
            allowClear = {false}
          />
          <label>{t("EndTime")}</label>
          <TimePicker
            defaultValue={newETime}
            onChange={(time) => (newETime = time)}
            // onChange={(e) => handleChange(e)}
            format={format}
            placeholder="Select End Time"
            allowClear = {false}
          />
        </div>
      ),
      onOk() {
        const updatedList = scheduleList.map((schedule,index) => {
          if (selectedIds.includes(schedule._id)) {
            return {
              ...schedule,
              sTime: newSTime.format(format),
              eTime: newETime.format(format),
            };
          }
          return schedule;
        });
        // const updatedList = scheduleList.map((schedule, index) => {
        //   if (selectedIds.includes(schedule._id)) {
        //     return {
        //       ...schedule,
        //       sTime: newSTime.format(format),
        //       eTime: newETime.format(format),
        //     };
        //     return schedule;
        //   }
        // });
        // Update the scheduleList with the updatedList
        setScheduleList(updatedList);
        Modal.destroyAll();
      },
      onCancel() {
        console.log("Cancel");
        Modal.destroyAll();
      },
    });
  }
  else{
    Message.error(t('Please select atleast one schedule'))    
      }
  }


  const onCheckWarning = async (values) =>{
    if(!position.latitude){
        Toaster("error", "Error", t("Your device is unable to pick your location, Please check your device settings"));
        return
    }
    setLoading(true);
       let obj = {
         auth: serviceDetails._id || formValues.auth,
         lat: position.latitude,
         lng: position.longitude,
         clientId: serviceDetails?.client_id || formValues.client_id,
       };
     // }
    if (serviceDetails) {
      const endPoint = showEndSchBtn ? "sch-end/check": "sch-start/check";
      AxiosInstance.put(endPoint, obj)
        .then(({ data }) => {
          setLoading(false);
          if (!data.success) {
            if (!Array.isArray(data?.message)) {
              Toaster("error", data.message, '');
              return;
            }
            if(data?.message === "Auth doesn't Exists."){
              Message.error(data?.message)
            } 
            const allInactive = data?.addressList?.every(location => !location.active);
            const onleGeoFence = data?.message?.length === 1;
            if(!showEndSchBtn){            
              if(data?.message?.length > 0){
                checkWarningModal(data, values,);
              } else {
                onFinish(values);
              }
          
            }

            if(showEndSchBtn){
              
               if(allInactive){
                    if(data.message.length === 0) onEndScheFnc();
                    else endWarningModal(data,values);
                }
              else{
                endWarningModal(data,values)
              }
             } 
          } else {
            if(!showEndSchBtn){  
            onFinish(values);
            }
            else{
              onEndScheFnc();
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
        }); // onScheStartClick(obj,type);
    }
  }

  const onChange = (value,option) => {
    console.log(`selected ${value}`);
    const lat = option.lat;
    const lng = option.lng;
    console.log("Selected Latitude:", lat);
    position.latitude = lat;
    position.longitude = lng;
    console.log("Selected Longitude:", lng);
  };

  const checkWarningModal = (message, obj,) => {    
    const allInactive = message?.addressList?.every(location => !location.active);
    message?.message[0].message ==="You are out of the Geo Fence. Please contact support." || message?.message[0].message ==="The Geo Coordinates doesn't exists for client address, Please contact your support"
        ? Modal.confirm({
            title: message?.message[0].message,
            confirmLoading: loading,
            icon: <ExclamationCircleFilled />,
            footer: (
              <div style={{ float: "inline-end" }}>
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={() => Modal.destroyAll()}
                >
                 {t("OK")}
                </Button>
              </div>
            ),
          })
        : Modal.confirm({
            title: "",
            okText: t("Yes"),
            size: "small",
            confirmLoading: loading,
            // icon: <ExclamationCircleFilled />,
            content: (
              <div>
                {message?.message?.map((item, index) => (
                  <div key={index}>
                    {item?.data?.covidCheck && (
                      <span>
                        <Checkbox
                          onChange={(e) => setIsChecked(e.target.checked)}
                        ></Checkbox>{" "}
                      </span>
                    )}{" "}
                     {item?.data?.covidCheck && item.message}

                    {item?.data?.communityService && (
                      <span>
                        <Checkbox
                          onChange={(e) => setIsCommunictyChecked(e.target.checked)}
                        ></Checkbox>{" "}
                      </span>
                    )}{" "}                     
                    {item?.data?.communityService && item.message}                   

                    {!allInactive && item?.data?.warning && item.message}
                    {allInactive && item?.data?.warning && item.message}
                    {(item?.data?.warning && !allInactive && message?.addressList?.length && index === 0)  ? (
                        <div style={{ marginBottom: 10 }}>
                          <div>
                            <label style={{ fontWeight: "500" }}>
                              {t("Please select a Location")}:{"        "}
                            </label>
                          </div>
                          <Select
                            showSearch
                            placeholder="Select a Location"
                            optionFilterProp="children"
                            onChange={onChange}
                            style={{ width: "100%" }}
                          >
                            {message?.addressList?.map((location) => (
                              <Option
                                key={location.address}
                                value={location.address}
                                lat={location.lat}
                                lng={location.lng}
                                disabled={!location.active}
                              >
                                {location.address}
                              </Option>
                            ))}
                          </Select>
                        </div>
                    ) : null}
                      {item?.data?.remoteService && item.message}
                    {item?.data?.remoteService && (
                      <>
                        {validatePin(obj?.clientId)}
                        <div>
                          <label style={{ fontWeight: "500" }}>
                            {t("Please enter PIN sent to client's mobile number")}
                          </label>
                          <Input
                            placeholder="Enter PIN"
                            ref={inputRef}
                            maxLength={4}
                          />
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            ),
            onOk() {
              const remoteServiceData = message?.message.filter(
                (item) => item.data.remoteService === true
              )[0]?.data;
              const pinValue = inputRef?.current?.input?.value?.trim();
              if (remoteServiceData) {
                if (pinValue !== "") {
                  onFinish(obj);
                  Modal.destroyAll();
                } else {
                  Message.error("Please Enter Pin");
                }
              } else {
                onFinish(obj);
                Modal.destroyAll();
              }
            },
            onCancel() {
              console.log("Cancel");
              fetchGeoloc();
              Modal.destroyAll();
            },
            
            okButtonProps: {
              className: "ant-btn-modal", 
            },
            
            cancelButtonProps: {
              className: "ant-btn-confirm-cancel",             
            },
          });
    
  }
  const endWarningModal = (message, obj, type) => { 
    message?.message[0].message ===
      "You are out of the Geo Fence. Please contact support." ||
    message?.message[0].message ===
      "The Geo Coordinates doesn't exists for client address, Please contact your support"
      ? Modal.confirm({
          title: message?.message[0].message,
          confirmLoading: loading,
          icon: <ExclamationCircleFilled />,
          footer: (
            <div style={{ float: "inline-end" }}>
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={() => Modal.destroyAll()}
              >
                {t("OK")}
              </Button>
            </div>
          ),
        })
      : Modal.confirm({
          title: "",
          okText: t("Yes"),
          confirmLoading: loading,
          icon: <ExclamationCircleFilled />,
          content: (
            <div>
              {message?.message?.map((item, index) => (
                <div key={index}>
                  {item.message}
                  {item?.data?.warning && message?.addressList?.length && (
                    <div style={{ marginBottom: 10 }}>
                      <div>
                        <label style={{ fontWeight: "500" }}>
                         {t("Please select a Location")}:{"        "}
                        </label>
                      </div>
                      <Select
                        showSearch
                        placeholder={t("Select a Location")}
                        optionFilterProp="children"
                        onChange={onChange}
                        style={{ width: "100%" }}
                      >
                        {message?.addressList?.map((location) => (
                          <Option
                            key={location.address}
                            value={location.address}
                            lat={location.lat}
                            lng={location.lng}
                            disabled={!location.active}
                          >
                            {location.address}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ),
          onOk() {
            onEndScheFnc(obj);
            Modal.destroyAll();
          },
          onCancel() {
            console.log("Cancel");
            fetchGeoloc();
            Modal.destroyAll();
          },

          okButtonProps: {
            className: "ant-btn-modal",
          },

          cancelButtonProps: {
            className: "ant-btn-confirm-cancel",
          },
        });
  
}

  const onFinish = (values,type) => {
    setLoading(true);      
    values.sDate = dayjs(values.sDate.$d).format("YYYY-MM-DD");
    values.eDate = dayjs(values.eDate.$d).format("YYYY-MM-DD"); 
    values.is_diagnosis = latestIsChecked.current || false;
    values.sTime = dayjs(values.sTime.$d).format("hh:mm A");
    values.eTime = dayjs(values.eTime.$d).format("hh:mm A");
    values.service = serviceDetails.service._id;
    values.companyId = serviceDetails.companyId._id;
    values.lat = position.latitude;
    values.lng = position.longitude;
    values.actualLng = actualPosition.longitude;
    values.actualLat = actualPosition.latitude;
    values.communityService = latestIsCommunictyChecked.current || false;

    let updatedValues = {}
    const pinValue = inputRef?.current?.input?.value?.trim();      
    if (pinValue) {
      if (pinValue?.length !== 4) {
        Message.info(t('Please enter 4 digit code'));
        setLoading(false);
        return;
      }
      else{
        updatedValues = {
          ...values,
          remotePin: pinValue,
      };
      }
    }

    const url = "emp/new-scheduler";
    AxiosInstance.post(url, pinValue ? updatedValues : values)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          Toaster("success", "Success", res.data.message);
          const ids = res.data || [];
          setScheduleList(res.data.data);
          const allIds = res.data.data.map((item) => item._id);
          setSelectedIds(allIds);
          setshowEndSchBtn(true);
          if (ids[0]) fetchOutServicesList(ids[0]);
          fetchGeoloc();
          // redirectSchListing(res.data)
          // handleReset();
        } else {
          if (res?.data?.is_diagnosis) {
            Modal.confirm({
              title: res?.data?.message,
              confirmLoading: loading,
              icon: <ExclamationCircleFilled />,    
              footer: (
                <div style={{float:'inline-end'}}>
                  <Button key="submit" type="primary" loading={loading} onClick={() => Modal.destroyAll()}>
                    OK
                  </Button>
                </div>     ),
            })
            fetchGeoloc();
            setIsChecked(false)
            setIsCommunictyChecked(false)
          }
          else {
            if (res?.data.message === "You have entered Invalid Pin") {
              Toaster("error", "Error", res?.data.message);
              fetchGeoloc();
              setIsChecked(false)
              setIsCommunictyChecked(false)
            } else {
              fetchGeoloc();
              Toaster("info", "Attention", res?.data.message);
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
    // }
  };

  const handleDraw = () => {
    setIsSignatureEmpty(false); // Set signature as not empty when drawing starts
  };

  const showWarningConfirm = ({ message, data, type = "" }) => {
    confirm({
      title: message,
      okText: t("Yes"),
      icon: <ExclamationCircleFilled />,
      content: <div></div>,
      onOk() {        
        warningConfirm(data, message,type);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const warningConfirm = (data, message,type) => {
    let obj = {
      ...data,
      ...(message.includes("...") ? { bypass: true } : { bypassTC: true }),
    };
    if(type === 'start'){
      onFinish(obj, "byPass"); // Call the API again with bypassTC set to true
    }
    else{
      onEndScheFnc();
    }
  };

  const onEndScheFnc = async () =>{
    setLoading(true)
    const sch = [];
    if (position.latitude && position.longitude) {
      let len = scheduleList.length;
      for (let index = 0; index < len; index++) {
        const element = scheduleList[index];
        if (selectedIds.includes(element._id)) {
          sch.push({
            sTime: element.sTime,
            eTime: element.eTime,
            id: element._id
          });
        }
      }
        if(selectedIds.length){
          const obj =  {lat: position.latitude,lng: position.longitude,actualLng:actualPosition.longitude,actualLat:actualPosition.latitude,sch}
          let endpoint = "emp/end-scheduler";
          AxiosInstance.post(endpoint,obj)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          Toaster("success", "Success", res.data.message);
          setShowEmployeeSignature(true);
        } else {
          if (res?.data?.data?.warning) {
            showWarningConfirm({
              message: res.data.message,
              data: obj,
              type: "end",
            });
          } else {
            if (res.data.message === "You have entered Invalid Pin") {
              Toaster("error", "Error", res.data.message);
              // showClientPinPrompt(values, "remote");
            } else {
              Toaster("info", "Attention", res.data.message);
            }
            // Toaster('error', 'Oops', res.data.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
        }
        else{
          Message.error('Select atleast one schedule.')   
          setLoading(false) 
            }
    }
  }

  const closeSignatureModal = () => {
    setShowEmployeeSignature(false);
    setShowClientSignature(false);
    redirectSchListing(formValues.eDate)
  }
  const getEmpSignature = (URL) => {
    signnature.eSign = URL
    employeeSignature();
  };

  const getClientSignature = (type) => {
    // schData.cSign = URL;
    signnature.cSign = type.getTrimmedCanvas().toDataURL("image/png");
    clientSignature();
    setIsSignatureEmpty(false);
  }
  const clearCanvas = () => {
    clientSigCanvas.current.clear();
    setIsSignatureEmpty(true);
  };

  const employeeSignature = () => {
    setWarningLoading(true);
    let endPoint = 'emp/multiple-schedule'
    let signatureData = {
      ids: selectedIds,
      eSign: signnature.eSign,
      lat: signnature.lat,
      lng: signnature.lng,
      clientId: formValues.clientId,
      isClient: true,
    }
    AxiosInstance.post(endPoint, signatureData)
      .then(({ data }) => {
        setWarningLoading(false);
        if (data.success) {
          setShowEmployeeSignature(false);
          Message.success(data.message);
          if (data.data && data.data.cSig && data.data.cSignService) {
            setDataForClientPin(selectedIds[0]);
            setShowClientSignature(true);
          }
          else if (data.data && data.data.cSig) {
            setShowClientSignature(true);
            setShowClientSignatureBox(true);
          }
          else {
             setPinValue("")
          setDataForClientPin("")
          setShowClientSignature("")
          setSelectedIds([])
          setShowClientSignatureBox('')
          redirectSchListing(formValues.eDate)
          }
        } else {
          Toaster("error", "ATTENTION", data.message);
        }
      })
      .catch((err) => {
        Toaster("error", "Error", err.message);
        setWarningLoading(false);
      });

    //  setShowEmployeeSignature(false);
    // Toaster("success", "Signature Successfully Saved", "");
  }
  const clientSignature = () => {
    setWarningLoading(true);
    let endPoint = 'emp/client-multi-sign'
    let signatureData = {
      ids: selectedIds,
      cSign: signnature.cSign,
      lat: signnature.lat,
      lng: signnature.lng,
      clientId:formValues.clientId,
      isClient: true,
    }
    AxiosInstance.post(endPoint, signatureData)
      .then(({ data }) => {
        setWarningLoading(false);
        if (data.success) {
          setShowClientSignature(false);
          Toaster("success", "Success", data.message);
          setPinValue("")
          setDataForClientPin("")
          setShowClientSignature("")
          setSelectedIds([])
          setShowClientSignatureBox('')
          clearCanvas()
          redirectSchListing(formValues.eDate)
          // getSchedules()
        } else {
          Toaster("info", "ATTENTION", data.message);
        }
      })
      .catch((err) => {
        Toaster("error", "Error", err.message);
        setWarningLoading(false);
      });
  }

  const handlePinChange = (e) => {
    const newPinValue
      = e.target.value;
    if (newPinValue.length === 4) {
      // If 4 digits are entered, trigger the API call
      setWarningLoading(true); // Set loading state to true
      let UserInfo = JSON.parse(localStorage.getItem("USER_INFO"));
      let endPoint = { e: 'emp/verify-pin', c: 'client/verify-pin' };
      let local = UserInfo.type ? endPoint[UserInfo.type] : '';
      let obj = {
        id: getDataForClientPin,
        pin: newPinValue, // Use the entered PIN value
      };
      AxiosInstance.post(local, obj)
        .then(({ data }) => {
          setWarningLoading(false); // Set loading state to false
          if (data.success) {
            setShowClientSignatureBox(true)
            Message.success(data.message);
          } else {
            Message.error(data.message);
          }
        })
        .catch((err) => {
          Message.error(err.message);
          setWarningLoading(false); // Set loading state to false
        });
    }
    setPinValue(newPinValue); // Update PIN value in state
  };
  //end confirm
  const fetchOutServicesList = (id) => {
    setLoading(true);
    const url = `emp/careplan-service?schedule_id=${id}`;
    AxiosInstance.get(url)
      .then((res) => {
        // Set the fetched data to the state
        if (res.data.data.careplan.length) {
          const taskList = [];
          res.data.data.careplan.forEach((x) => {
            taskList.push(x.task);
          });
          setSchData((prevState) => ({ ...prevState, tasks: taskList }));
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const handleReset = (type = "") => {
    if (!type) {
      form.resetFields();
      setFormValues({});
      setServiceDetails({});
      setClientServices([]);
      setScheduleList([])
      setshowEndSchBtn(false)
    } else {
      setTimeout(() => {
        form.setFieldsValue({ auth: undefined });
      }, 100);
    }
  };
  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) => {
    // If option is a string, trim and lowercase it before checking
    if (typeof option === "string") {
      return option.trim().toLowerCase().includes(input.trim().toLowerCase());
    }
    // If option has children, recursively call filterOption on each child
    if (option?.children && Array.isArray(option.children)) {
      return option.children.some((child) => filterOption(input, child));
    }
    // Return false for non-string options and options without children
    return false;
  };
  const redirectSchListing = (data) => {
    const date = moment.utc(data).format("YYYY-MM-DD");
    // const date = moment.utc(data.data.sDate).format('MM-DD-YYYY');
    // redirectSchedule.push({pathname:`/home/${date}`})
    // const eDate = data;
    redirectSchedule.push({
      pathname: "/home",
      state: { selectedSch: date },
    });
  };

  const renderFormField = (field) => {
    const {
      type,
      name,
      label,
      options,
      inputType,
      placeholder,
      min_date,
      max_date,
    } = field;
    switch (type) {
      case "date":
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[{ required: field.required, message: `${t("Please select")}  ${label}` }]}
          >
            <DatePicker
              placeholder={placeholder}
              format={"MM/DD/YYYY"}
              disabledDate={(current) => {
                return current && (current < min_date || current > max_date);
               }
              }
              size="large"
            />
          </Form.Item>
        );

      case "select_client":
        return (
          <Form.Item
            label={label}             
            name={name}
            rules={[
              { required: field.required, message: `${t("Please select")} ${label}` },
            ]}
          >
            <Select
              size="large"
              disabled= {showEndSchBtn}
              placeholder={placeholder}
              showSearch
              filterOption={filterOption}
              onChange={handleClientChange}
              loading={clientList.length === 0}
            >
              {clientList.map((option) => (
                <Option key={option._id} value={option._id}>
                  {option.fName} {option.lName}{" "}
                  {option.evv_hrs && <span>({option.evv_hrs})</span>}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      case "select_service":
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[
              { required: field.required, message: `${t("Please select")} ${label}` },
            ]}
          >
            <Select
              size="large"
              disabled= {showEndSchBtn}
              placeholder={placeholder}
              // onChange={handleServiceChange}
              // loading={clientServices.length === 0}
            >
              {clientServices.map((service) => (
                <Option key={service._id} value={service._id}>
                  {moment(service.sDate).format("MM-DD-YYYY")} {" / "}
                  {moment(service.eDate).format("MM-DD-YYYY")} {" / "} {service.pAuth} {" / "}
                  {service?.service?.desc}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[
              { required: field.required, message: `${t("Please select")} ${label}` },
            ]}
          >
            <Select placeholder={placeholder} size="large">
              {options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );

      case "time":
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[
              { required: field.required, message: `${t("Please select")} ${label}` },
            ]}
          >
            <TimePicker
              placeholder={placeholder}
              size="large"
              use12Hours
              hangeOnScroll
              needConfirm={false}
              format="hh:mm A"
            />
          </Form.Item>
        );
      case "input":
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[
              { required: field.required, message: `Please enter ${label}` },
            ]}
          >
            <Input size="large" type={inputType} placeholder={placeholder} />
          </Form.Item>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Form
        onValuesChange={handleFormChange}
        form={form}
        className="multiScheduler-info"
        onFinish={onCheckWarning}
        layout="vertical"
        style={{ padding: "0px 100px" }}
      >
        <Row gutter={[16, 0]}>
          {Formfield.map((field, index) => (
            <Col key={index} xs={24} sm={8}>
              {renderFormField(field)}
            </Col>
          ))}
        </Row>
        {serviceDetails?._id && serviceDetails?.isHssType && (
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label={t("SelectHSSType")}
                name="hssType"
                rules={[
                  {
                    required: false,
                    message: "Please Select Time",
                  },
                ]}
              >
                <Select size="large" placeholder={t("SelectHSSType")}>
                  {serviceDetails.hssTypeList.map((x, index) => (
                    <Option key={index} value={x}>
                      {x}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {formValues.hssType === "Remote Direct" && (
              <Col span={12}>
                <Form.Item
                  label={t("SelectRemote")}
                  name="remoteDirectOptions"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Time",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Select Remote Direct Options"
                  >
                    <Option value="Office">Office</Option>
                    <Option value="Community">Community</Option>
                    <Option value="Others">Others</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {serviceDetails?._id && serviceDetails?.isMileage && (
              <Col xs={24} sm={12}>
                <Form.Item
                  label= {t("EnterMileage")}
                  name="mileage"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter mileage",
                    },
                  ]}
                >
                  <Input size="large" placeholder= {t("EnterMileage")} />
                </Form.Item>
              </Col>
            )}
            {serviceDetails?._id && (
              <Col
                xs={24}
                sm={formValues.hssType === "Remote Direct" ? 12 : 24}
              >
                <Form.Item label={t("Service Hours Info")}>
                  <Button
                    type="dashed"
                    size={"large"}
                    style={{ width: "100%", height: "40px" }}
                  >
                    <span>{t("PerDay")}: {serviceDetails?.perday.toFixed(2)}</span>,{" "}
                    <span>{t("Used")}: {serviceDetails?.used.toFixed(2)}</span>,
                    <span>
                      {t("Remaining")}: {serviceDetails?.remaining.toFixed(2)}
                    </span>
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
        )}
        <Form.Item style={{ float: "right" }}>
          <Button
          className="ant-btn-form"
            style={{ marginRight: "10px" }}
            type="primary" danger
            onClick={() => handleReset("")}
          >
            {t("Reset")}
          </Button>
          <Button className="ant-btn-form" type="primary" htmlType="submit" loading={loading}>
          {showEndSchBtn ?  t('EndSchedule'): t('StartSchedule')}
          </Button> 
        </Form.Item>
      </Form>

      <Spin spinning={loading} fullscreen  size="large" />
      {scheduleList.length ? <Divider plain>{t("EndSchedule")}</Divider>:null}

      {scheduleList.length ? (
                <div style={{padding:'0px 20px',marginBottom:'30px'}}>
                  {selectAllActive ? (
                    <Button type="primary" onClick={handleSelectAll} className="ant-btn-form">
                      {t("SelectAll")}
                    </Button>
                  ) : (
                    <Button type="primary" danger onClick={handleDeselectAll} className="ant-btn-form">
                     {t("UnselectAll")}
                    </Button>
                  )}
                  <Button type="primary" onClick={editSchedule} className="ant-btn-form" style={{ marginLeft:'2px' }} >
                     {t("EditSchedule")}
                    </Button>
                </div>
              ) : null}


      <Row gutter={[16, 16]} className="mt-3" style={{ margin: "0px 10px" }}>
        {scheduleList.map((item, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={6} xl={8}>
            <Badge.Ribbon
              placement="start"
              text={t(item.scheduleStatus)}
              color={
                item.scheduleStatus === "Completed" ? "#32CD32" : "#1E90FF"
              }
            >
            <Card
              className="ant-tabs-tabpane"
              style={{
                background: "#f0f2f5",
                borderLeftWidth: "3px",
                borderLeftColor: "#1E90FF",
              }}
            >
              <Meta
                title={
                  <div style={{}}>
                    <div
                      style={{
                        position: "absolute",
                        right: "4px",
                        top: "0.5rem",
                      }}
                    >
                      <Checkbox
                        checked={selectedIds.includes(item._id)}
                        onChange={() => handleCheckboxChange(item._id)}
                      ></Checkbox>
                    </div>
                    <div
                      style={{
                        marginTop: 33,
                        marginLeft: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* <div>
                          {dayjs(item?.eDate).format('MMM DD (ddd)')}
                        </div> */}
                      <div>
                      <div>
                                                                    <span>
                                                                        {dayjs(item?.eDate).add(1, 'day').format('MM/DD/YYYY')}
                                                                    </span>
                                                                </div>
                        <span>
                          {item?.clientId?.fName} {item?.clientId?.lName} -{" "}
                          <span
                            style={{ fontSize: "0.7rem", color: "#8546A7" }}
                          >
                            {" "}
                            {item?.service?.desc}
                          </span>
                        </span>
                      </div>
                      <div>
                        <span>
                          {" "}
                          {item?.sTime || item?.aSTime } -{" "}
                          { item?.eTime || item?.aETime}
                        </span>
                      </div>
                      <div>
                        {t("Contact")}:
                        <span style={{ color: "#8546A7", fontSize: "0.7rem" }}>
                          {" "}
                          {item?.clientId?.phone1}
                        </span>
                      </div>
                    </div>
                    <div
                      className="progress-wrapper"
                      style={{ marginBottom: "10px", fontWeight: "bold" }}
                    >
                      {item?.evvHrs && (
                        <div
                          className="progress-bar"
                          style={{ width: `${item?.total}%` }}
                        >
                          {item?.auth ? (
                            <>
                              <span>
                                {t("Used")}: {item?.auth?.used || "0"} / {t("Remaining")}:{" "}
                                {item?.auth?.remaining || "0"} {t("PerDay")}:{" "}
                                {item?.auth?.perday || "0"}
                              </span>
                            </>
                          ) : (
                            <span>{t("SA Not Exist")}</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                }
              />
            </Card>
            </Badge.Ribbon>
          </Col>
        ))}
      </Row>

      {
        showEmployeeSignature && (
          <SignatureModal
            showSignature={showEmployeeSignature}
            handleCancel={closeSignatureModal}
            type="employee"
            loading={warningLoading}
            onDone={getEmpSignature} // Pass the callback function from the parent component
          />
        )
      }
      <Modal
        title={t("ClientSignature")}
        open={showClientSignature}
        onCancel={closeSignatureModal}
        closable={true}
        footer={null}
        maskClosable={false}
      >
        {getDataForClientPin && (
          <div>
            <div>
              <Title level={5} style={{ fontSize: '11px', color: 'red' }}>
              {t("EnterClientSignature")}
              </Title>
            </div>
            <div>
              <Input placeholder={t("Enter PIN")} onChange={handlePinChange} value={getPinValue} />
            </div>
          </div>

        )}
        {showClientSignatureBox && (
          <div>
            {/* <div>
              <Title level={5} style={{ fontSize: '11px', color: 'red' }}>
                Client Signature
              </Title>
            </div> */}
            <div style={{color:'red'}}>
                <p>
                {t("PrivacyCertify")}
                </p>
            </div>
            <div style={{ border: '1px dotted #ccc' }}>
              <SignatureCanvas canvasProps={{ width: 460, height: 250 }} ref={clientSigCanvas} onBegin={handleDraw}/>
            </div>
            <div className="mt-25 text-right">
              <Button style={{ marginRight: '4px' }} className="ant-btn-modal" type="primary" danger onClick={clearCanvas}>
               {t("Clear")}
              </Button>
              <Button disabled={isSignatureEmpty} type="primary" className="ant-btn-modal" loading={warningLoading} onClick={() => getClientSignature(clientSigCanvas.current)}>
                {t("Done")}
              </Button>
            </div>
          </div>
        )
        }
      </Modal>
    </>
  );
};

export default MultiScheduler;
