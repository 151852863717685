import { useState, useEffect, useRef, useContext } from "react";
import MyLocation from "./../../utility/MyLocation";
import { RefreshComponet } from "../../utility/UserDetails";
import { GetlocationFromLocalStorage } from "../../utility/CompanyDetails";
import { useLocation, useHistory } from "react-router-dom";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import moment from "moment";
import { Message } from "../../utility/MessageUtil";
import SignatureCanvas from 'react-signature-canvas';
import { message as messageApi } from 'antd';
import MapWithMarkers from './../../components/userProfile/MapWithMarkers'
import GMap from './../../components/userProfile/GMap'
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  ConfigProvider,
  Card,
  Col,
  Row,
  Button,
  Tooltip,
  Typography,
  TimePicker,
  Badge,
  Spin,
  Checkbox,
  Calendar,
  Alert,
  Modal,
  Input,
  Tag, Select, Empty
} from "antd";
import {
  EnvironmentOutlined,
  InfoCircleOutlined,
  ExclamationCircleFilled,
  CalendarTwoTone,
  DoubleRightOutlined,
} from "@ant-design/icons";
import fetchGeolocation from "../../utility/CurrentLocation";
import SignatureModal from "./Cli-Emp-Signatures";
import ReactCardFlip from "react-card-flip";
const { Title } = Typography;
const { Meta } = Card;
const { confirm } = Modal;
const { Option } = Select;
const format = "h:mm A";
const cityLocation = [
  {
    id: 1,
    name: "New York City",
    latitude: 40.7128,
    longitude: -74.006,
  },
  {
    id: 2,
    name: "Paris",
    latitude: 48.8566,
    longitude: 2.3522,
  },
  {
    id: 3,
    name: "Tokyo",
    latitude: 35.6895,
    longitude: 139.6917,
  },
  {
    id: 4,
    name: "Sydney",
    latitude: -33.8688,
    longitude: 151.2093,
  },
  {
    id: 5,
    name: "Cairo",
    latitude: 30.033,
    longitude: 31.2336,
  },
];


const Home = () => {
  const { t } = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
  const uType = localStorage.getItem("type");
  const state = localStorage.getItem("state") || "";
  const displayItems = JSON.parse(localStorage.getItem('Home_Screen'));
  const startEndTimeRestriction = JSON.parse(localStorage.getItem('startEndTimeRestriction'));
  const location = useLocation();
  const clientSigCanvas = useRef();
  const history = useHistory();
  const inputRef = useRef(null);
  const SchDetail = useLocation();
  const today = moment(new Date()).format("YYYY-MM-DD");
  const [selectedClient, setSelectedClient] = useState(null);
  const [getDataForClientPin, setDataForClientPin] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warningLoading, setWarningLoading] = useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [showCalender, setShowCalender] = useState(false);
  const [clientUnauthCount, setclientUnauthCount] = useState(null);
  const [selectedDate, setSelectedDate] = useState(() =>
    dayjs(SchDetail?.state?.selectedSch || today)
  );
  const [selectedTime, setSelectedTime] = useState(dayjs()); // State to store selected time, initialized with current time
  const [scheduleList, setScheduleList] = useState([]);
  const [getCutSchedule, setCutSchedule] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isCommunictyChecked, setIsCommunictyChecked] = useState(false);
  const latestIsChecked = useRef(isChecked);
  const latestIsCommunictyChecked = useRef(isCommunictyChecked);
  const [isMapModalOpen, setIsModalModalOpen] = useState(false);
  const [markers, setmarkers] = useState([]);
  const [timeDifference, setTimeDifference] = useState(t('Loading...')); // State to hold the time difference
  const [schedule, setSchedule] = useState({}); // State to hold the data
  // Update latestIsChecked whenever isChecked changes
  useEffect(() => {
    latestIsChecked.current = isChecked;
  }, [isChecked]);

  useEffect(() => {
    latestIsCommunictyChecked.current = isCommunictyChecked;
  }, [isCommunictyChecked]);

  const [showEmployeeSignature, setShowEmployeeSignature] = useState(false);
  const [showClientSignature, setShowClientSignature] = useState(false);
  const [showClientSignatureBox, setShowClientSignatureBox] = useState(false);
  const [getPinValue, setPinValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  //careplan services
  const [carePlanData, setCarePlanData] = useState({
    servicesList: [],
    outcomeSection: [],
    isOpen: false,
  });
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [changeFlipCard, setChangeFlipCard] = useState({ fixed: false, message: '', data: null, remote: '' })
  const [flipType, SetFlipType] = useState('')
  //current location
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [actualPosition, setactualPosition] = useState({ latitude: null, longitude: null });
  const [schData, setSchData] = useState({
    _id: "",
    eTime: "",
    sTime: "",
    eDate: "",
    notes: [
      {
        note: "",
        addedBy: "",
      },
    ],
    eSign: "",
    lat: "",
    lng: "",
  });

  const schDataRef = useRef(schData);

  const onClose = () => {
    setSelectedClient(null);
    setOpen(false);
  };
  const clearCanvas = () => {
    clientSigCanvas.current.clear();
    setIsSignatureEmpty(true);
  };
  useEffect(() => {
    if (userInfo) {
      setUserDetails(userInfo);
    }
  }, []);

  // Update the ref whenever schData changes
  useEffect(() => {
    schDataRef.current = schData;
  }, [schData]);

  // Function to update schData
  const updateSchData = (data) => {
    setSchData(data);
  };


  useEffect(() => {
    if (userDetails) {
      selectDate(
        SchDetail?.state?.selectedSch ||
        moment(selectedDate).format("MM-DD-YYYY")
      );
    }
  }, [userDetails]); // Run this effect whenever userDetails changes

  useEffect(() => {
    fetchGeoloc();
  }, []);

  const [isTimeBreaks, setTimeBreaks] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTimeDifference(schedule); // Call updateTimeDifference every 5 seconds
    }, 10000);
    return () => clearInterval(interval); // Clean up on component unmount
  }, [schedule]); // Add c as dependency to run useEffect when c changes

  const selectDate = (value, type = "") => {
    if (location.state !== null && location.state !== undefined) {
      value = location.state.endDate;
      setSelectedDate(dayjs(moment(value).format("MM-DD-YYYY"), "MM-DD-YYYY"));
      // setSelectedDate(dayjs(value).format('MM-DD-YYYY'));
    }

    let data = "";
    if (type) {
      setSelectedDate(
        dayjs(moment(value.$d).format("MM-DD-YYYY"), "MM-DD-YYYY")
      );
      data = moment(value.$d).format("MM-DD-YYYY");
    } else data = value;
    setFlippedCard(null);
    setWarningLoading(true);
    setScheduleList([]);
    setCutSchedule([]);
    setUnauthCount("");
    setclientUnauthCount("");
    if (uType === "e") {
      displayItems &&
      displayItems.homeBtnPayrollWeek && getEmpCutSchHr(data);
    }
    const endPoint = { e: "emp/my-schedule", c: "client/my-schedule" };
    AxiosInstance.get(endPoint[uType], { params: { date: data } })
      .then((res) => {
        setWarningLoading(false);
        history.push({
          pathname: "/home",
          state: null,
        });
        if (res.data.dirtyBit) RefreshComponet(res.data.dirtyBit)

        if (res && res.data.data && Array.isArray(res.data.data)) {
          setTimeBreaks(res?.data?.data[0]?.isAllowedBreakTime || false);
          const inProgressTask = res.data.data.find(task => task.scheduleStatus === "Inprogress");
          res.data.data.forEach((x) => {
            if (!x.breakType) x.breakType = "in";
            if (inProgressTask) {
              if (x._id === inProgressTask._id) {
                x.showTimer = true;
                setSchedule(x);
              } else x.showTimer = false;
            }
            //   if (x.aSTime) {
            //   const start = moment.utc(x.aSTime, 'hh:mm A');
            //   const etime = new Date().toLocaleTimeString([], {
            //     hour: '2-digit',
            //     minute: '2-digit',
            //     hour12: true,
            //   });
            //   const end = moment.utc(etime, 'hh:mm A');
            //   const d = moment.duration(end.diff(start));
            //   x.tTime = d['_data'].hours + 'h' + ':' + d['_data'].minutes + 'm';
            //  }
            if (x.auth?.remaining > 0) {
              const total = x.auth?.remaining + x.auth?.used;
              x.total = (x.auth?.used / total) * 100;
            } else x.total = 0;
          });

        }
        setScheduleList(res.data.data);

      })
      .catch((error) => {
        setWarningLoading(false);
        if (error?.response?.status === 401){
          history.replace("/sign-in");
          localStorage.clear();
        }
        setScheduleList([]);
        console.error("Error fetching data:", error);
      });
    if (displayItems && displayItems.homeBtnEmpMissingSign) getUnauthCount();

    if (uType == "e" && displayItems && displayItems.homeBtnCliMissingSign) {
      getclientUnauthCount();
    }
  };
  const updateTimeDifference = (x) => {
    if (x.aSTime) {
      const etime = new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
      const start = moment.utc(x.aSTime, 'hh:mm A');
      const end = moment.utc(etime, 'hh:mm A');
      const d = moment.duration(end.diff(start));
      const tTime = d.hours() + 'H' + ':' + d.minutes() + 'M';
      setTimeDifference(tTime);
      // return tTime;
    }

  };

  const getColor = (c) => {
    switch (c.scheduleStatus) {
      case "New":
        return "teal";
      case "Completed":
        return "rgb(50, 205, 50)";
      case "Ended":
        return "#808080";
      case "Cancelled":
        return "#FF0000";
      case "Inprogress":
        return "#F2AA4CFF";
      case "Finished":
        return "#13c2c2";
      case "Unverified":
        return "#000000";
      default:
        return "#1E90FF";
    }
  };
  const handleOkMapModal = () => {
    setIsModalModalOpen(false);
  };
  const openMapModal = (data) => {
    debugger
    setmarkers([])
    if ((data?.clientId?.lat && data?.clientId?.lng) || (data?.clientId?.evvAddrLat && data?.clientId?.evvAddrLng)) {
      const location = JSON.parse(localStorage.getItem("location"));
      const uLoc = { lat: parseFloat(location.lat), lng: parseFloat(location.long), icon: 'https://revivingcare.s3.us-west-2.amazonaws.com/62d7accd7218375a56dd02f1/Clients/1711944206665.png' };
      const cLoc = {
        lat: data?.clientId?.lat || data?.clientId?.evvAddrLat, lng: data?.clientId?.lng || data?.clientId?.evvAddrLng, icon: 'https://revivingcare.s3.us-west-2.amazonaws.com/62d7accd7218375a56dd02f1/Clients/1711944349173.png'
        // 'https://placehold.co/20x20/black/white?font=Raleway&text=CT'
      };
      let markers = [];
      markers = [cLoc, uLoc];
      setmarkers(markers)
      setIsModalModalOpen(true);
    }

  }

  const fetchGeoloc = async () => {
    try {
      const location = JSON.parse(localStorage.getItem("location"));
      if (location) {
        setPosition({
          latitude: parseFloat(location.lat),
          longitude: parseFloat(location.long),
        });
        setactualPosition({
          latitude: parseFloat(location.lat),
          longitude: parseFloat(location.long),
        });
      } else {
        const fetchedPosition = await fetchGeolocation();
        setPosition({
          latitude: fetchedPosition.latitude,
          longitude: fetchedPosition.longitude,
        });
        setactualPosition({
          latitude: fetchedPosition.latitude,
          longitude: fetchedPosition.longitude,
        });
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error.message);
    }
  };
  const getEmpCutSchHr = (value) => {
    let endPoint = { e: "emp/cut-sch-hours" };
    AxiosInstance.get(endPoint[uType], { params: { date: value } })
      .then((response) => {
        setLoading(false);
        setCutSchedule(response.data.data);
      })
      .catch((error) => {
        setCutSchedule([]);
        console.error("Error fetching data:", error);
      });
  };


  const getclientUnauthCount = () => {
    let endPoint = "emp/finished-schedules";
    AxiosInstance.get(endPoint)
      .then((response) => {
        setclientUnauthCount(response.data.data);
      })
      .catch((error) => {
        setclientUnauthCount("");
        console.error("Error fetching data:", error);
      });
  };

  const [UnauthCount, setUnauthCount] = useState(null);
  const getUnauthCount = () => {
    let endPoint = {
      e: "emp/ended-schedules",
      c: "client/timecard?count=true&status=Finished",
    };
    AxiosInstance.get(endPoint[uType])
      .then((response) => {
        setUnauthCount(response.data.data);
      })
      .catch((error) => {
        setUnauthCount("");
        console.error("Error fetching data:", error.message);
      });
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const confirmTimePop = (val, isChecked) => {
    let obj = {
      is_resp: false,
      is_diagnosis: isChecked || false,
      _id: val._id,
      sTime: moment(selectedTime, "HH:mm A").format("hh:mm A"),
      checkIn: new Date().toISOString(),
      lat: position.latitude,
      long: position.longitude,
      clientId: val?.clientId._id,
    };
    if (val) {
      onScheStartClick(obj);
    }
  };

  //onflip functionality
  const [flippedCard, setFlippedCard] = useState(null);

  const onFlipCard = (index, type) => {
    setSelectedTime(dayjs());
    SetFlipType(type);
    setFlippedCard(index === flippedCard ? null : index);
  };
  const onFlipBackCard = () => {
    // Handle cancel action here, flip the card back
    setFlippedCard(null);
    SetFlipType('');
    setSelectedTime(dayjs());
    // setSelectedTime(moment());
  };

  const clickOnYes = (val, flipType) => {
    if (flipType === 'start') {
      onCheckScheduleWarning(val, 'notDirect');
    }
  }
  //end of onflip functionality

  const onCheckScheduleWarning = async (values, type) => {
    if (!position.latitude) {
      Toaster("error", "Error", t("Your device is unable to pick your location, Please check your device settings"));
      return
    }
    setWarningLoading(true);
    let obj = {
      _id: values._id,
      lat: position.latitude,
      lng: position.longitude,
      clientId: values?.clientId._id,
    };
    // }
    if (values) {
      const endPoint = "sch-start/check";
      AxiosInstance.put(endPoint, obj)
        .then(({ data }) => {
          setWarningLoading(false);
          if (!data.success) {
            if (!Array.isArray(data?.message)) {
              Toaster("error", data.message, '');
              return;
            }
            if (data?.message === "Auth doesn't Exists.") {
              Message.error(data?.message)
            }
            const allInactive = data?.addressList?.every(location => !location.active);
            if (allInactive) {
              if (data.message.length == 0) onStartClickFunc(values, type);
              else checkWarningModal(data, values, type);
            }
            else {
              checkWarningModal(data, values, type);
            }
          } else {
            onStartClickFunc(values, type);
          }
        })
        .catch((error) => {
          setWarningLoading(false);
          console.error("Error fetching data:", error);
        }); // onScheStartClick(obj,type);
    }
  }



  const checkWarningModal = (message, obj, type) => {
    const allInactive = message?.addressList?.every(location => !location.active);
    message?.message[0].message === "You are out of the Geo Fence. Please contact support." || message?.message[0].message === "The Geo Coordinates doesn't exists for client address, Please contact your support"
      ? Modal.confirm({
        title: message?.message[0].message,
        confirmLoading: loading,
        icon: <ExclamationCircleFilled />,
        footer: (
          <div style={{ float: "inline-end" }}>
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => Modal.destroyAll()}
            >
              OK
            </Button>
          </div>
        ),
      })
      : Modal.confirm({
        title: message?.message[0].message === 'Was the client Hospitalized / Care facility during this time period?' ||
          message?.message[0].message === 'You are out of the Geo fence, Do you want to start community service?'
          ? "" : "",// "Do you want to start this schedule?",
        okText: t("Yes"),
        size: "small",
        confirmLoading: loading,
        icon: <ExclamationCircleFilled />,
        content: (
          <div>
            {message?.message?.map((item, index) => (
              <div key={index}>
                {item?.data?.covidCheck && (
                  <span>
                    <Checkbox
                      onChange={(e) => setIsChecked(e.target.checked)}
                    ></Checkbox>{" "}
                  </span>
                )}{" "}
                {item?.data?.covidCheck && item.message}
                {item?.data?.communityService && (
                  <span>
                    <Checkbox
                      onChange={(e) => setIsCommunictyChecked(e.target.checked)}
                    ></Checkbox>{" "}
                  </span>
                )}{" "}
                {item?.data?.communityService && item.message}
                {item?.data?.remoteService && item.message}
                {!allInactive && item?.data?.warning && item.message}
                {allInactive && item?.data?.warning && item.message}
                {item?.data?.warning &&
                  !allInactive &&
                  message?.addressList?.length && index == 0 && (
                    <div style={{ marginBottom: 10 }}>
                      <div>
                        <label style={{ fontWeight: "500" }}>
                          Please select a Location{"        "}
                        </label>
                      </div>
                      <Select
                      allowClear={true}
                        showSearch
                        placeholder="Select a Location"
                        optionFilterProp="children"
                        onChange={onChange}
                        style={{ width: "100%" }}
                      >
                        {message?.addressList?.map((location) => (
                          <Option
                            key={location.address}
                            value={location.address}
                            lat={location.lat}
                            lng={location.lng}
                            disabled={!location.active}
                          >
                            {location.address}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                {item?.data?.remoteService && (
                  <>
                    {validatePin(obj?.clientId._id)}
                    <div>
                      <label style={{ fontWeight: "500" }}>
                        {t("Please enter PIN sent to client's mobile number")}
                      </label>
                      <Input
                        placeholder="Enter PIN"
                        ref={inputRef}
                        maxLength={4}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ),
        onOk() {
          const remoteServiceData = message?.message.filter(
            (item) => item.data.remoteService === true
          )[0]?.data;
          const pinValue = inputRef?.current?.input?.value?.trim();
          if (remoteServiceData) {
            if (pinValue !== "") {
              onStartClickFunc(obj, type);
              Modal.destroyAll();
            } else {
              Message.error("Please Enter Pin");
            }
          } else {
            onStartClickFunc(obj, type);
            Modal.destroyAll();
          }
        },
        onCancel() {
          console.log("Cancel");
          fetchGeoloc();
          Modal.destroyAll();
        },

        okButtonProps: {
          className: "ant-btn-modal",
        },

        cancelButtonProps: {
          className: "ant-btn-confirm-cancel",
        },
      });

  }
  const endWarningModal = (message, obj, type) => {
    message?.message[0].message ===
      "You are out of the Geo Fence. Please contact support." ||
      message?.message[0].message ===
      "The Geo Coordinates doesn't exists for client address, Please contact your support"
      ? Modal.confirm({
        title: message?.message[0].message,
        confirmLoading: loading,
        icon: <ExclamationCircleFilled />,
        footer: (
          <div style={{ float: "inline-end" }}>
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => Modal.destroyAll()}
            >
              OK
            </Button>
          </div>
        ),
      })
      : Modal.confirm({
        // title: "Do you want to end this schedule?",
        okText: t("Yes"),
        confirmLoading: loading,
        icon: <ExclamationCircleFilled />,
        content: (
          <div>
            {message?.message?.map((item, index) => (
              <div key={index}>
                {item.message}
                {item?.data?.warning && message?.addressList?.length && (
                  <div style={{ marginBottom: 10 }}>
                    <div>
                      <label style={{ fontWeight: "500" }}>
                        Please select a Location{"        "}
                      </label>
                    </div>
                    <Select
                    allowClear={true}
                      showSearch
                      placeholder="Select a Location"
                      optionFilterProp="children"
                      onChange={onChange}
                      style={{ width: "100%" }}
                    >
                      {message?.addressList?.map((location) => (
                        <Option
                          key={location.address}
                          value={location.address}
                          lat={location.lat}
                          lng={location.lng}
                          disabled={!location.active}
                        >
                          {location.address}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            ))}
          </div>
        ),
        onOk() {
          EndSchedule(obj, "directEndScheduleFlow");
          Modal.destroyAll();
        },
        onCancel() {
          console.log("Cancel");
          fetchGeoloc();
          Modal.destroyAll();
        },

        okButtonProps: {
          className: "ant-btn-modal",
        },

        cancelButtonProps: {
          className: "ant-btn-confirm-cancel",
        },
      });

  }

  const onStartClickFunc = async (values, type) => {
    // if (type === "ignoreCovid") {
    //   showSetSchedulePop(values);
    // } else if (type === "directStartSchedule") {
    let obj = {
      is_resp: false,
      is_diagnosis: latestIsChecked.current || false,
      _id: values._id,
      sTime: dayjs(selectedTime, "HH:mm A").format("hh:mm A"),
      checkIn: new Date().toISOString(),
      lat: position.latitude,
      long: position.longitude,
      actualLng: actualPosition.longitude,
      actualLat: actualPosition.latitude,
      clientId: values?.clientId._id,
      communityService: latestIsCommunictyChecked.current || false,
    };
    if (values) {
      const pinValue = inputRef?.current?.input?.value?.trim();
      if (pinValue) {
        if (pinValue?.length !== 4) {
          Message.info('Please enter 4 digit code');
          return;
        }
        let obj1 = {
          ...obj,
          remotePin: pinValue,
        };
        onScheStartClick(obj1, type);
      }
      else {
        onScheStartClick(obj, type);
      }
    }
    // }
  };

  const onScheStartClick = (obj, type) => {
    setWarningLoading(true);
    let endPoint = { e: "emp/start-schedule", c: "emp/start-schedule" };
    AxiosInstance.post(endPoint[uType], obj)
      .then(async ({ data }) => {
        setWarningLoading(false);
        if (data.success) {
          // Toaster("success", "Success", data.message);
          setFlippedCard(null);
          setChangeFlipCard({ ...changeFlipCard, fixed: false })
          selectDate(selectedDate, "calender");
          fetchGeoloc();
          setIsChecked(false)
          setIsCommunictyChecked(false)
          getCarePlanService(data.data._id);
        } else {
          setSelectedTime(dayjs());
          if (data?.is_diagnosis) {
            Modal.confirm({
              title: data?.message,
              confirmLoading: loading,
              icon: <ExclamationCircleFilled />,
              footer: (
                <div style={{ float: 'inline-end' }}>
                  <Button key="submit" type="primary" loading={loading} onClick={() => Modal.destroyAll()}>
                    OK
                  </Button>
                </div>),
            })
            fetchGeoloc();
            setIsChecked(false)
            setIsCommunictyChecked(false)
          }
          else {
            if (data.message === "You have entered Invalid Pin") {
              Toaster("error", "Error", data.message);
              fetchGeoloc();
              setIsChecked(false)
              setIsCommunictyChecked(false)
              // showClientPinPrompt(obj, "remote");
            } else {
              fetchGeoloc();
              Toaster("info", "Attention", data.message);
            }
          }
        }
      })
      .catch((error) => {
        setWarningLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const getCarePlanService = (id) => {
    setLoading(true);
    let URL = "emp/careplan-service";
    AxiosInstance.get(URL, {
      params: {
        schedule_id: id,
      },
    })
      .then(({ data }) => {
        if (data.success) {
          if (
            data?.data?.careplan?.length > 0 ||
            data?.data?.outcome_section?.length > 0
          ) {
            setCarePlanData((prevState) => ({
              ...prevState,
              isOpen: true,
            }));
          }

          let newTasks = [];
          let newServices = [];
          let newServicesList = data.data.careplan || [];
          if (newServicesList.length) {
            newServicesList.forEach((x) => {
              newTasks.push(x.task);
            });
          }
          let newOutcomeSection = data.data.outcome_section || [];
          if (newOutcomeSection.length) {
            newOutcomeSection.forEach((x) => {
              x.note = "";
              newServices.push(x.outcome + " " + x.qualifier);
            });
          } else {
            newServices = newTasks;
          }
          setCarePlanData((prevState) => ({
            ...prevState,
            servicesList: newServicesList || [],
            outcomeSection: newOutcomeSection || [],
          }));
          handleCancel("close");
        } else {
          // Handle failure
          console.log("Care plan doesn't exists against this client");
          // setOutcomeSection([]);
          setCarePlanData({
            servicesList: [],
            outcomeSection: [],
          });
          // You might want to handle any dismiss logic here
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const confirmHospitalization = (val, isChecked) => {
    showSetSchedulePop(val, isChecked);
  };

  const warningConfirm = (data, message) => {
    let obj = {
      ...data,
      ...(message.includes("...") ? { bypass: true } : { bypassTC: true }),
    };
    onScheStartClick(obj); // Call the API again with bypassTC set to true
  };

  const handleCancel = (type = "") => {
    if (!type) {
      setCarePlanData((prevState) => ({
        ...prevState,
        isOpen: false,
      }));
    } else {
      setTimeout(() => {
        setCarePlanData((prevState) => ({
          ...prevState,
          isOpen: false,
        }));
      }, 5000);
    }
  };

  //End schedule section
  const EndSchedule = (c, type) => {
    if (c.breakType == "out") {
      Toaster(
        "error",
        "Error",
        "Please end your break first"
      );
      return;
      // const obj: any = { endScheduleData: data, userInfo: userInfo };
      // obj.endScheduleData.enableQR = enableQR || false;
      // navigation.navigate('ScheduleDetail', obj);
    }
    // else{
    //   toast.show("Please your end break", { type: "danger", placement: "top", duration: 3000 });
    // }
    setSelectedClient(c);
    // const currentTime = dayjs().format("hh:mm A");
    if (type === "directEndScheduleFlow") {
      const schData = schDataRef.current;
      schData._id = c._id;
      schData.eDate = dayjs(c.eDate).format("MM-DD-YYYY");
      schData.sTime = c.aSTime || c.sTime;
      schData.eTime = dayjs().format("hh:mm A");
      schData.lat = position.latitude;
      schData.lng = position.longitude;
      schData.actualLat = actualPosition.latitude;
      schData.actualLng = actualPosition.longitude;
      submitSchedule();
    } else if (type === 'redirect') {
      history.push({
        pathname: "/ScheduleDetails",
        state: { selectedClient: c, userDetails: userDetails },
      });
    }
  };

  const getRefSchedule = (id) => {
    setWarningLoading(true)
    let local = 'sch-detail/' + id.refScheduleId;
    AxiosInstance.get(local)
      .then(response => {
        setWarningLoading(false)
        history.push({
          pathname: "/ScheduleDetails",
          state: { selectedClient: response?.data?.data, userDetails: userDetails },
        });
      })
      .catch(error => {
        setWarningLoading(false)
        console.error("Error fetching data:", error);
      });
  }

  const submitSchedule = async () => {
    const schData = schDataRef.current; // Get the latest schData from the ref
    const localStorgeLoc = await GetlocationFromLocalStorage();
    if (localStorgeLoc && !localStorgeLoc.lat) {
      const location = await fetchGeolocation();
      if (location) {
        if (location.latitude && location.longitude) {
          localStorgeLoc.lat = location.latitude;
          localStorgeLoc.long = location.longitude;
          schData.lat = localStorgeLoc.lat;
          schData.lng = localStorgeLoc.long;
          localStorage.setItem("location", JSON.stringify(localStorgeLoc));
        } else {
          Toaster(
            "error",
            "Error",
            t("Your device is unable to pick your location, Please check your device settings")
          );
        }
      }
    }
    // else {
    //   schData.lat = localStorgeLoc.lat;
    //   schData.lng = localStorgeLoc.long;
    // }
    if (schData.lng && schData.lat) {
      let endPoint = { e: "emp/end-schedule", c: "client/end-schedule" };
      AxiosInstance.post(endPoint[uType], schData)
        .then(({ data }) => {
          setWarningLoading(false);
          if (data.success) {
            Toaster("success", "Success", data.message);
            fetchGeoloc();
            setShowEmployeeSignature(true);
          } else {
            if (data?.data?.warning) openWarningPops(data.message);
            else {
              Toaster("error", data.message);
              // setMapModal(true)
            }
          }
        })
        .catch((err) => {
          console.log(err);
          Toaster("error", "Error", err.message);
        });
    } else {
      setWarningLoading(false);
      Toaster(
        "error",
        t("Your device is unable to pick your location, Please check your device settings"),
        ""
      );
    }
  };
  const openWarningPops = (message) => {
    confirm({
      title: message,
      icon: <ExclamationCircleFilled />,
      content:
        (
          <div>
            {message.includes("...") &&
              <>
                <label style={{ fontWeight: '500' }}>{t("Please select a Location")}</label>
                <Select
                  showSearch
                  allowClear={true}
                  placeholder={t("Select a Location")}
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  style={{ width: '100%' }}
                  filterOption={filterOption}
                >
                  {cityLocation.map(location => (
                    <Option key={location.id} value={location.id}>
                      {location.name}
                    </Option>
                  ))}
                </Select>
              </>
            }
          </div>),

      onOk() {
        const schData = schDataRef.current;
        if (message.includes("...")) schData.bypass = true;
        else schData.bypassTC = true;
        submitSchedule();
      },
      onCancel() {
        schData.eTime = "";
        console.log("Cancel");
      },
    });
  };
  // Updated handleChange function
  const handleChange = (value) => {
    if (!value) return;
    let newValue = value;
    if (value.$isDayjsObject) {
      newValue = dayjs(value).format("hh:mm A");
    }

    setSchData((prevState) => ({
      ...prevState,
      eTime: newValue,
    }));
  };
  const getEmpSignature = (URL, note) => {
    schData.eSign = URL;
    // schData.notes.note = note;
    employeeSignature(note);
  };

  const getClientSignature = (type) => {
    // schData.cSign = URL;
    schData.cSign = type.getTrimmedCanvas().toDataURL("image/png");
    clientSignature();
    setIsSignatureEmpty(false);
  };
  const handleDraw = () => {
    setIsSignatureEmpty(false); // Set signature as not empty when drawing starts
  };

  const employeeSignature = (note) => {
    setSignatureLoading(true);
    let endPoint = { e: "emp/schedule-sign", c: "client/schedule-sign" };
    let signatureData = {
      _id: schData._id,
      eSign: schData.eSign,
      lat: schData.lat,
      lng: schData.lng,
      note: note
    };
    AxiosInstance.post(endPoint[uType], signatureData)
      .then(({ data }) => {
        setSignatureLoading(false);
        if (data.success) {
          setShowEmployeeSignature(false);
          Message.success(data.message);
          // Toaster("success", "Success", data.message);

          if (data.data && data.data.cSign && data.data.cSignService) {
            setDataForClientPin(data.data._id);
            setShowClientSignature(true);
          }
          // setDataForClientPin(data.data._id);
          else if (data.data && data.data.cSign) {
            setShowClientSignature(true)
            setShowClientSignatureBox(true);
          }
          // else backToHomePage(schData.eDate)
        } else {
          Toaster("error", "ATTENTION", data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        Toaster("error", "Error", err.message);
        setSignatureLoading(false);
      });

    //  setShowEmployeeSignature(false);
    // Toaster("success", "Signature Successfully Saved", "");
  };

  const clientSignature = () => {
    setSignatureLoading(true);
    let endPoint = {
      e: "emp/client/schedule-sign",
      c: "client/client/schedule-sign",
    };
    let signatureData = {
      _id: schData._id,
      cSign: schData.cSign,
      lat: schData.lat,
      lng: schData.lng,
    };
    AxiosInstance.post(endPoint[uType], signatureData)
      .then(({ data }) => {
        setSignatureLoading(false);
        if (data.success) {
          setShowClientSignature(false);
          setPinValue("")
          Toaster("success", "Success", data.message);
          updateSchData({});
          selectDate(selectedDate, "calender");
          setDataForClientPin("")
          setShowClientSignatureBox('')
          // backToHomePage(schData.eDate)
        } else {
          Toaster("info", "ATTENTION", data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        Toaster("error", "Error", err.message);
        setSignatureLoading(false);
      });
  };


  const closeSignatureModal = () => {
    setShowEmployeeSignature(false);
    setShowClientSignature(false);
    setPinValue("")
    setShowClientSignatureBox('')
    updateSchData({});
    selectDate(selectedDate, "calender");
  };

  //End of End Schedule

  const showClientDetails = (c, button) => {
    setSelectedClient(c);
    history.push({
      pathname: "/ScheduleDetails",
      state: { selectedClient: { ...c, button }, userDetails: userDetails },
    });
  };

  const showbreakTime = (data) => {
    // setQrCodeData(data);
    setSelectedTime(dayjs().format("h:mm A"));
    console.log(data);
    confirm({
      title: data?.breakType === "in" ? t("Start break") : t("End break"),
      okText: t("Yes"),
      confirmLoading: loading,
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          {data?.breakType === "in" ? (`${t('Its')} ${dayjs().format("h:mm A")}. ${t("Are you sure you want to start your break in")} ${userInfo?.companyId?.sName}?`) : (`${t("It's")} ${dayjs().format("h:mm A")}. ${t("Are you sure you want to end your break in")} ${userInfo?.companyId?.sName}?`)}
        </div>
      ),
      onOk() {
        // confirmTimePop(val, isChecked);
        addBreaks(data)
      },
      onCancel() {
        console.log("Cancel");
        Modal.destroyAll();
        // setIsChecked(false);
      },
    });
  }

  const addBreaks = (obj) => {
    setLoading(true);
    const endPoint = "schedule/breaks";
    const data = { id: obj._id, breakType: obj.breakType };
    if (obj.breakType === "out") {
      data.breakId = obj?.breaks[obj?.breaks.length - 1]._id
    }
    AxiosInstance.post(endPoint, data)
      .then(async ({ data }) => {
        setLoading(false);
        if (data.success) {
          // refAddbreakRBSheet.current.close();
          // toast.show(data?.message, { type: "success", placement: "top", duration: 3000 });
          messageApi.success(data?.message)
          // selectDate(moment(selectedDate, "YYYY/MM/DD").format("MM-DD-YYYY"));
          selectDate(selectedDate, "calender");
        } else {
          messageApi.success(data?.message)
          // toast.show(data?.message, { type: "danger", placement: "top", duration: 3000 });
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.error("Error fetching data:", error);
      });
  };



  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option == "Client Missing Signature") {
      history.push({
        pathname: "/history",
        state: { missingSignData: "Client Missing Signature" },
      });
    } else {
      history.push({
        pathname: "/history",
        state: { missingSignData: "Missing Signature" },
      });
    }
    console.log(`Clicked option: ${option}`);
  };

  //confirm modal
  const showHospitalizationConfirm = (val) => {
    confirm({
      title: "Hospitalization & Care Facility Alert?",
      okText: "Yes",
      icon: <ExclamationCircleFilled />,
      content: (
        <Checkbox onChange={(e) => setIsChecked(e.target.checked)}>
          Was the client Hospitalized / Care facility during this time period?
        </Checkbox>
      ),
      onOk() {
        setIsChecked((isChecked) => {
          if (isChecked) {
            showOkConfirm(val, isChecked);
          } else {
            confirmHospitalization(val, isChecked);
          }
          return isChecked;
        });
      },
      onCancel() {
        console.log("Cancel");
        setIsChecked(false);
      },
    });
  };

  const showOkConfirm = (val, isChecked) => {
    confirm({
      title: "Hospitalization & Care Facility Confirmation",
      okText: "Okay",
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to continue. If "YES" please click Okay',
      onOk() {
        setIsChecked(false);
        showSetSchedulePop(val, isChecked);
      },
      onCancel() {
        console.log("Cancel");
        setIsChecked(false);
      },
    });
  };

  const showSetSchedulePop = (val, isChecked) => {
    confirm({
      title: "Do you want to start this schedule?",
      okText: t("Yes"),
      confirmLoading: loading,
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <TimePicker
            defaultValue={selectedTime} // You may set your desired default time
            format="hh:mm A" // Adjust the format as per your requirement
            onChange={handleTimeChange}
            needConfirm={false}
            allowClear={false}
          />
        </div>
      ),
      onOk() {
        confirmTimePop(val, isChecked);
      },
      onCancel() {
        console.log("Cancel");
        Modal.destroyAll();
        setIsChecked(false);
      },
    });
  };

  const onChange = (value, option) => {
    console.log(`selected ${value}`);
    const lat = option?.lat;
    const lng = option?.lng;
    position.latitude = lat;
    position.longitude = lng;
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());


  const showWarningConfirm = ({ message, data, remote = "" }) => {
    confirm({
      title: message,
      okText: "Yes",
      icon: <ExclamationCircleFilled />,
      content:
        (
          <div>
            {remote === "remote" || message.includes("...") &&
              <>
                <label style={{ fontWeight: '500' }}>Please select a Location</label>
                <Select
                  showSearch
                  placeholder="Select a Location"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  style={{ width: '100%' }}
                  filterOption={filterOption}
                >
                  {cityLocation.map(location => (
                    <Option key={location.id} value={location.id}>
                      {location.name}
                    </Option>
                  ))}
                </Select></>
            }
          </div>
        ),
      onOk() {
        if (remote === "remote") {
          validatePin(data);
        } else {
          warningConfirm(data, message);
        }
      },
      onCancel() {
        console.log("Cancel");
        setIsChecked(false);
      },
    });
  };

  const clickOnWarning = (data, message, remote) => {
    if (remote === "remote") {
      validatePin(data);
    } else {
      warningConfirm(data, message);
    }

  }
  const validatePin = (values) => {
    let endPoint = "client/pin-remote";
    AxiosInstance.post(endPoint, { clientId: values })
      .then(({ data }) => {
        if (data.success) {
          Message.success(data.message);
          // showClientPinPrompt(values);
        } else {
          Toaster("error", "Error", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const showClientPinPrompt = (data) => {
    let shouldCloseModal = true;
    confirm({
      title: "Please enter PIN sent to client's mobile number",
      icon: <ExclamationCircleFilled />,
      content: <Input placeholder="Enter PIN" ref={inputRef} />,
      onOk: () => {
        const pinValue = inputRef.current.input.value.trim();
        if (pinValue) {
          let obj = {
            ...data,
            remotePin: pinValue,
          };
          onScheStartClick(obj);
          shouldCloseModal = true; // Close the modal
        } else {
          // Toaster("error", "Error", "Please Enter Client's PIN");
          Message.error("Please Enter Client's PIN");
          shouldCloseModal = false; // Keep the modal open
        }
      },
      onCancel() {
        console.log("Cancel");
        shouldCloseModal = true; // Close the modal if Cancel is clicked
      },
      afterClose: () => {
        if (!shouldCloseModal) {
          showClientPinPrompt(data); // Re-open the modal if it should remain open
        }
      },
    });
  };


  const handlePinChange = (e) => {
    const newPinValue = e.target.value;
    if (newPinValue.length === 4) {
      // If 4 digits are entered, trigger the API call
      setWarningLoading(true); // Set loading state to true
      let endPoint = { e: 'emp/verify-pin', c: 'client/verify-pin' };
      let obj = {
        id: getDataForClientPin,
        pin: newPinValue, // Use the entered PIN value
      };
      AxiosInstance.post(endPoint[uType], obj)
        .then(({ data }) => {
          setWarningLoading(false); // Set loading state to false
          if (data.success) {
            setShowClientSignatureBox(true)
            messageApi.success(data.message);
          } else {
            messageApi.error(data.message);
          }
        })
        .catch((err) => {
          console.error(err);
          messageApi.error(err.message);
          setWarningLoading(false); // Set loading state to false
        });
    }
    setPinValue(newPinValue); // Update PIN value in state
  };

  return (
    <>
      <Spin
        size="large"
        spinning={warningLoading}
        fullscreen
        tip=
        {t("FetchingYourData")}

      />
      {/* <div styles={{ width: '100%' }}> <DatePicker onChange={selectDate} format={dateFormat} /></div> */}
      <div styles={{ width: "100%" }}>
        {displayItems && displayItems.homeCalendar ? (
          <Alert
            onClick={() => setShowCalender(!showCalender)}
            message={
              <h4 style={{ fontWeight: "bold", margin: "0px" }}>
                {!showCalender ? (
                  <CalendarTwoTone style={{ fontSize: "18px" }} />
                ) : (
                  <CalendarTwoTone />
                )}{" "}
                {SchDetail?.state?.selectedSch ||
                  dayjs(selectedDate).format("MM-DD-YYYY")}
              </h4>
            }
            style={{ textAlign: "center", padding: "5px", cursor: "pointer" }}
          />


        ) : <Alert
          message={
            <h4 style={{ fontWeight: "bold", margin: "0px" }}>
              {SchDetail?.state?.selectedSch ||
                dayjs(selectedDate).format("MM-DD-YYYY")}
            </h4>
          }
          style={{ textAlign: "center", padding: "5px", cursor: "pointer" }}
        />

        }

        {showCalender && state !== 'IL' && (
          <Calendar
            fullscreen={false}
            value={selectedDate}
            onChange={(e) => selectDate(e, "calender")}
          />
        )}
        <Row gutter={5} style={{ marginTop: 5, marginBottom: 5 }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
            {displayItems &&
              displayItems.homeBtnCliMissingSign &&
              clientUnauthCount > 0 && (
                <Button block
                  type={
                    selectedOption === "Client Missing Signature"
                      ? "primary"
                      : "default"
                  }
                  onClick={() => handleOptionClick("Client Missing Signature")}
                  style={{ flex: 1, marginRight: "5px", background: "#91caff" }}
                >
                  {t('Client Missing Signs')}
                  ({clientUnauthCount})
                </Button>
              )}
          </Col>
          <Col span={uType === "c" ? 24 : 12} xs={24} sm={24} md={12} lg={uType === "c" ? 24 : 12} xl={uType === "c" ? 24 : 12}>
            {displayItems &&
              displayItems.homeBtnEmpMissingSign &&
              UnauthCount > 0 && (
                <Button block
                  type={
                    selectedOption === "Missing Signature" ? "primary" : "default"
                  }
                  onClick={() => handleOptionClick("Missing Signature")}
                  style={{ flex: 1, background: "#91caff" }}
                >
                  {t('Missing Signs')}({UnauthCount})
                </Button>
              )}
          </Col>
        </Row>
        {/* <div
          style={{
            marginTop: "5px",
            marginBottom: "5px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {displayItems &&
            displayItems.homeBtnCliMissingSign &&
            clientUnauthCount > 0 && (
              <Button
                type={
                  selectedOption === "Client Missing Signature"
                    ? "primary"
                    : "default"
                }
                onClick={() => handleOptionClick("Client Missing Signature")}
                style={{ flex: 1, marginRight: "5px", background: "#91caff" }}
              >
                {clientUnauthCount} Client Missing Signature
              </Button>
            )}
          {displayItems &&
            displayItems.homeBtnEmpMissingSign &&
            UnauthCount > 0 && (
              <Button
                type={
                  selectedOption === "Missing Signature" ? "primary" : "default"
                }
                onClick={() => handleOptionClick("Missing Signature")}
                style={{ flex: 1, background: "#91caff" }}
              >
                {UnauthCount} Missing Signature
              </Button>
            )}
        </div> */}
        {displayItems &&
          displayItems.homeBtnPayrollWeek && getCutSchedule && getCutSchedule.eDate && (
            <Alert
              message={
                <div>
                  <div style={{ fontWeight: "bold" }}>
                    {t('PayrollWeek')}
                  </div>
                  <div>
                    <span>
                      {moment.utc(getCutSchedule?.sDate).format("MM/DD/YYYY")} {t("to")}{" "}
                    </span>
                    <span>
                      {moment(getCutSchedule?.eDate).format("MM/DD/YYYY")}
                    </span>
                  </div>
                  <div>
                    {t('CompletedHours')}

                    ={" "}
                    {getCutSchedule &&
                      getCutSchedule.totalHours &&
                      getCutSchedule.totalHours.toFixed(2)}
                  </div>
                </div>
              }
              style={{ textAlign: "center" }}
            />
          )}
      </div>
      <div className="layout-content" style={{ paddingBottom: "80px" }}>
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {scheduleList.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              className="mb-24"
            >
              <ReactCardFlip
                className="characterCard"
                isFlipped={index === flippedCard}
                flipDirection="horizontal"
              >
                <Badge.Ribbon
                  placement="start"
                  text={t(c.scheduleStatus)}
                  color={getColor(c)}
                >
                  <Card
                    className="ant-tabs-tabpane"
                    style={{
                      background: "#f0f2f5",
                      borderLeftWidth: "3px",
                      borderLeftColor: getColor(c),
                    }}
                  >
                    <Col xs={24}>
                      <Meta
                        title={
                          <div style={{}}>
                            <div
                              style={{
                                position: "absolute",
                                right: "0",
                                top: "0.5rem",
                              }}
                            >

                              {c.scheduleStatus === "New" && uType === "e" && (
                                <ConfigProvider
                                  theme={{
                                    token: {
                                      colorPrimary: '#32CD32',
                                    },
                                  }}
                                >
                                  <Button defaultHoverBg="#ffffff"
                                    className="ant-btn-card"
                                    type="primary"
                                    // loading={loading}
                                    onClick={() => onFlipCard(index, "start")
                                      // onCheckScheduleWarning(
                                      //   c,
                                      //   "directStartSchedule",
                                      //   index
                                      // )
                                    }
                                    style={{ marginRight: "8px" }}
                                  >
                                    {t("Start")}
                                  </Button>
                                </ConfigProvider>
                              )}
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                right: "0",
                                top: "0.5rem",
                              }}
                            >
                              {c.scheduleStatus === "Inprogress" &&
                                uType === "e" && (
                                  <Button danger disabled={c?.eTime === '11:59 PM' && c?.service?.configuration?.overNightFlag && c?.refScheduleId}
                                    className="ant-btn-card"
                                    type="primary"
                                    // loading={loading}
                                    onClick={() => EndSchedule(c, "redirect", index)}
                                  >
                                    {t("End")}
                                  </Button>
                                )}
                            </div>
                            <div
                              style={{ marginTop: 33 }}
                            // onClick={() => showDrawer(c)}
                            >
                              {c.clientId && (
                                <span>
                                  <span>
                                    {c.clientId?.fName} {c.clientId?.lName}
                                  </span>
                                  <span style={{fontSize: "0.6rem" }}>
                                    {" "}
                                    {c.clientId?.evv_hrs}
                                  </span>
                                  <span style={{ fontSize: "0.6rem" }}>
                                    {" - "} {c?.service?.desc}
                                  </span>
                                </span>
                              )}
                              {c.empId && (
                                <span>
                                  {c.empId?.fName} {c.empId?.lName}
                                </span>
                              )}
                              <div>
                                {c.aSTime || c.sTime} to {c?.scheduleStatus != "Inprogress" ? c?.aETime || c?.eTime : null} {c?.scheduleStatus != "Inprogress" ? "" : '- - -  '}{" "}
                                {uType === "c" && (
                                  <Tag
                                    style={{ float: "inline-end" }}
                                    color="processing"
                                  >
                                    {dayjs(c?.eDate).add(1, 'day').format("MMM DD")}
                                  </Tag>

                                )}
                              </div>
                              {c?.scheduleStatus !== "New" && isTimeBreaks && c?.total_breaks ? <div>
                                <span style={{ fontSize: "0.9rem" }}>
                                  {t("Total Break Time")}: {c?.total_breaks} ({t("Min")})
                                </span>
                              </div> : null}
                            </div>
                            <div></div>
                          </div>
                        }
                        description={
                          <div style={{ marginBottom: "5px" }}>
                            {c.clientId?.address ? (
                              <Tooltip title={c.clientId?.address} color={'#26546C'}>
                                <div>
                                  {c.clientId?.address?.length > 41
                                    ? c.clientId?.address?.substring(0, 41) + "..."
                                    : c.clientId?.address}
                                </div></Tooltip>
                            ) :
                              // <Tooltip title={c.clientId?.address} color={'#26546C'}>
                              <div>
                                {c.clientId?.street}
                                {/* {c.clientId?.city} {c.clientId?.state} */}
                              </div>
                              // </Tooltip>
                            }

                            {c.clientId && (
                              <div>
                                {t("Contact")}
                                :{" "}
                                {c?.clientId?.phone1 && c?.clientId?.phone1.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1)$2-$3"
                                )}
                              </div>
                            )}
                            {c.empId && (
                              <div>
                                {t("Contact")}:{" "}
                                {c?.empId?.phone && c?.empId?.phone.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1)$2-$3"
                                )}
                              </div>
                            )}
                            <div
                              style={{ fontSize: "1.3rem", color: "#1E90FF" }}
                            >
                              {uType === "e" ? <Tooltip title={t("Location")} onClick={() => openMapModal(c)}>
                                <span>
                                  <EnvironmentOutlined />
                                </span>
                              </Tooltip> : null}
                              <Tooltip title={t("Details")}>
                                <span style={{ marginLeft: "10px" }}>
                                  <InfoCircleOutlined
                                    onClick={() =>
                                      showClientDetails(c, "false")
                                    }
                                  />
                                </span>
                              </Tooltip>
                              {c.aSTime && c.showTimer && (
                                <Tag style={{ marginLeft: "7px" }} color="blue">{timeDifference}</Tag>
                                //  <Tag style={{ marginLeft: "7px" }} color="blue">{c.tTime.toUpperCase()}</Tag>
                              )}

                              {uType === "e" && c?.scheduleStatus === "Inprogress" && isTimeBreaks && (<Tag color="purple"
                                style={{ marginLeft: 5, cursor: 'pointer' }}
                                onClick={() =>
                                  showbreakTime(c)
                                }
                              >
                                {c?.breakType == "in" ? t("☕︎ Start a break") : t("☕︎ End break")}

                              </Tag>)}
                              {uType === "e" && (c?.scheduleStatus === "Inprogress" || c?.scheduleStatus === "New") && c?.refScheduleId && (
                                <Tag style={{ marginLeft: "7px" }} color="red">
                                  <DoubleRightOutlined onClick={() => getRefSchedule(c, index)} />
                                </Tag>
                              )}


                            </div>

                            <div
                              className="progress-wrapper"
                              style={{
                                marginBottom: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              {uType === "e" && c?.evvHrs && (
                                <div
                                  className="progress-bar"
                                  style={{ width: `${c?.total}%` }}
                                >
                                  {uType === "e" && c?.auth ? (
                                    <>
                                      <span>
                                        {t("Used")}
                                        : {c?.auth?.used.toFixed(2) || "0"} /
                                        {t("Remaining")}  : {c?.auth?.remaining.toFixed(2) || "0"}{" "}
                                        {t("PerDay")}  : {c?.auth?.perday.toFixed(2) || "0"}
                                      </span>
                                    </>
                                  ) : (
                                    <span>
                                      {t("SA Not Exist")}
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </Col>
                  </Card>
                </Badge.Ribbon>

                <Card
                  style={{
                    background: "#f0f2f5",
                    borderColor: getColor(c),
                    borderLeftWidth: "3px",
                  }}
                >
                  {flipType === "start" && (
                    <Col xs={24}>
                      <Meta
                        style={{ marginTop: 20 }}
                        title={t("Do you want to start this schedule")}
                        description={
                          <>
                            <div style={{ padding: "10px 0px" }}>
                              <TimePicker
                                value={selectedTime} // You may set your desired default time
                                format="hh:mm A" // Adjust the format as per your requirement
                                onChange={handleTimeChange}
                                needConfirm={false}
                                disabled={startEndTimeRestriction}
                              // allowClear = {false}
                              />
                            </div>
                            <div
                              style={{
                                float: "inline-end",
                                padding: "25px 0px",
                              }}
                            >
                              <Button type="primary"
                                className="ant-btn-form"
                                danger onClick={onFlipBackCard}>{t("Cancel")} </Button>
                              <Button
                                type="primary"
                                className="ant-btn-form"
                                style={{ marginLeft: "8px" }}
                                onClick={() => clickOnYes(c, 'start')}
                              >
                                {t("Yes")}
                                {" "}
                              </Button>
                            </div>
                          </>
                        }
                      />
                    </Col>
                  )}
                  {flipType === "end" && (
                    <Col xs={24}>
                      <Meta
                        style={{ marginTop: 20 }}
                        title={t("Do you want to end this schedule?")}
                        description={
                          <>
                            <div style={{ padding: "10px 0px" }}>
                              <TimePicker
                                defaultValue={dayjs(c?.eTime, format)}
                                onChange={(e) => handleChange(e)}
                                format={format}
                                disabled={startEndTimeRestriction}
                                placeholder={t("Select End Time")}
                                allowClear={false}
                                needConfirm={false}
                              />
                            </div>
                            <div
                              style={{
                                float: "inline-end",
                                padding: "25px 0px",
                              }}
                            >
                              <Button type="primary" danger
                                className="ant-btn-form" onClick={onFlipBackCard}>{t("Cancel")} </Button>
                              <Button
                                type="primary"
                                className="ant-btn-form"
                                style={{ marginLeft: "8px" }}
                                onClick={() => clickOnYes(c, 'end')}
                              >
                                {t("Yes")}{" "}
                              </Button>
                            </div>
                          </>
                        }
                      />
                    </Col>
                  )}
                </Card>
              </ReactCardFlip>
            </Col>
          ))}
        </Row>
        <Row style={{ marginTop: '10rem', flexDirection: 'row', justifyContent: 'center' }}>
          {(scheduleList.length === 0) && (
            <Empty description={t("NoDataFound")} style={{ textAlign: 'center' }} />
          )}
        </Row>
        {/* <Spin
          spinning={warningLoading}
          fullscreen
          tip="Please Wait..."
          size="large"
        /> */}
      </div>
      <Modal
        title={t("Activities")}
        width={1000}
        top
        open={carePlanData.isOpen}
        onCancel={(e) => handleCancel("")}
        closable={true}
        footer={null}
      >
        {carePlanData.outcomeSection.length ? (
          <Card
          // title={"Goals/Outcome Section"}
          // style={{ margin: 10 }}
          // hoverable={true}
          >
            <h5 style={{ margin: 10 }}>{t("GoalsOutcomeSection")}</h5>
            <Row gutter={[2, 2]} style={{ marginBottom: "10px" }}>
              {carePlanData.outcomeSection?.map((item, index) => (
                <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
                  <Card
                    style={{
                      margin: "10px",
                      background: "#f0f2f5",
                      position: "relative",
                      boxShadow: "none",
                    }}
                  >
                    <Meta
                      title={
                        <>
                          <div style={{ marginTop: "10px", padding: "0 14px", fontSize: '13px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                            {item.outcome}
                            <div>
                              {item.measure_type === "Count" && (
                                <Tag bordered={false} color="lime">
                                  <span>{item.qualifier}</span>
                                </Tag>
                              )}
                              {item.measure_type === "Rating" && (
                                <Tag bordered={false} color="lime">
                                  <span>{item.qualifier}</span>
                                </Tag>
                              )}
                              {item.measure_type !== "Count" &&
                                item.measure_type !== "Rating" && (
                                  <Tag bordered={false} color="lime">
                                    <span>{item.qualifier}</span>
                                  </Tag>
                                )}
                            </div>
                          </div>
                          <div style={{ marginTop: '-8px' }}
                          >
                            <span
                              style={{
                                marginTop: 10,
                                padding: "0 14px",
                                marginBottom: "10px",
                                fontSize: '13px',
                                fontWeight: 'normal',
                                textWrap: 'pretty'
                              }}
                            >
                              {item.instruction}
                            </span>

                          </div>
                        </>
                      }
                    // description={

                    // }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        ) : null}
        {carePlanData.servicesList.length ? (
          <Card style={{ marginTop: "10px" }}>
            <h5 style={{ margin: 10 }}>{t('Services')}</h5>
            <Row gutter={[2, 2]} style={{ marginBottom: "10px" }}>
              {carePlanData.servicesList?.map((item, index) => (
                <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
                  <Card
                    style={{
                      margin: "10px",
                      background: "#f0f2f5",
                      position: "relative",
                      boxShadow: "none",
                      fontSize: '13px'
                    }}
                  >
                    <Meta
                      title={
                        <>
                          <div style={{ marginTop: "10px", padding: "0 14px", fontSize: '13px' }}>
                            {item.task.split(":").slice(0, 2).join(":")}
                          </div>
                          <div
                            style={{
                              marginTop: 10,
                              padding: "0 14px",
                              marginBottom: "10px",
                              fontSize: '13px',
                              fontWeight: 'normal',
                              marginTop: '-2px',
                              textWrap: 'pretty'
                            }}
                          >
                            {item.instruction}
                          </div>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        ) : null}
      </Modal>

      {showEmployeeSignature && (
        <SignatureModal
          showSignature={showEmployeeSignature}
          handleCancel={closeSignatureModal}
          type="employee"
          loading={signatureLoading}
          isAddNotes={true}
          onDone={getEmpSignature} // Pass the callback function from the parent component
        />
      )}
      {/* {showClientSignature && (
        <SignatureModal
          showSignature={showClientSignature}
          handleCancel={closeSignatureModal}
          type="client"
          loading={signatureLoading}
          onDone={getClientSignature} // Pass the callback function from the parent component
        />
      )} */}

      <Modal
        title={t("ClientSignature")}
        open={showClientSignature}
        onCancel={closeSignatureModal}
        closable={true}
        footer={null}
        maskClosable={false}
      >
        {getDataForClientPin && (
          <div>
            <div>
              <Title level={5} style={{ fontSize: "11px", color: "red" }}>
                {t("EnterClientSignature")}
              </Title>
            </div>
            <div>
              <Input
                disabled={showClientSignatureBox ? true : false}
                placeholder="Enter PIN"
                onChange={handlePinChange}
                value={getPinValue}
              />
            </div>
          </div>
        )}
        {showClientSignatureBox && (
          <div>
            <div>
              <Title level={5} style={{ fontSize: "11px", color: "red" }}>
                {t("ClientSignature")}
              </Title>
            </div>
            <div style={{ border: "1px dotted #ccc" }}>
              <SignatureCanvas
                canvasProps={{ width: 460, height: 250 }}
                ref={clientSigCanvas}
                onBegin={handleDraw}
              />
            </div>
            <div className="mt-25 text-right">
              <Button
                style={{ marginRight: "4px" }}
                className="ant-btn-modal"
                type="primary"
                danger
                onClick={clearCanvas}
              >{t("Clear")}
              </Button>
              <Button
                disabled={isSignatureEmpty}
                className="ant-btn-modal"
                type="primary"
                loading={signatureLoading}
                onClick={() => getClientSignature(clientSigCanvas.current)}
              > {t("Done")}
              </Button>
            </div>
          </div>
        )}
      </Modal>
      <Modal className="custom-modal" title={t("User Location")} footer={null} open={isMapModalOpen} onCancel={handleOkMapModal}>
        <>
          <GMap markers={markers} />
          {/* <MapWithMarkers markers={markers} /> */}
        </>
      </Modal>
    </>
  );
};
export default Home;



